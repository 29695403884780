export const downloadBlob = (data, fileName, type) => {
  const bl = new Blob([data], {
    type
  })
  if (navigator.appVersion.toString().indexOf(".NET") > 0) {
    window.navigator.msSaveBlob(bl, fileName)
  } else {

    const a = document.createElement("a")
    a.href = URL.createObjectURL(bl)
    a.download = fileName
    a.hidden = true
    document.body.appendChild(a)
    a.click()
    a.remove()
  }
}