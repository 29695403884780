<template>
  <div>
    <ag-grid-vue
      id="grid"
      ref="grid"
      style="width:calc(100vw - 2px); max-width:calc(100vw - 2px);"
      :style="{height: gridHeight}"
      class="ag-theme-balham"
      :column-defs="columnDefs"
      :row-data="rowData"
      :grid-options="gridOptions"
      @grid-ready="onGridReady"
    />
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import { mapGetters } from "vuex"

import * as texts from "@/services/texts.js"

export default {
  components: {
    AgGridVue
  },
  props: {
    fatData: {
      type: Object,
      default: () => null
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    vectors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      gridApi: null,
      gridOptions: null,
      columnApi: null,
      columnDefs: null,
      columnDefsInternal: null,
      gridHeight: null,
      rowData: null,
      rowsProcessed: [],
      vectorsChanged: true,
      gridReady: false
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn"])
  },
  watch: {
    gridReady() {
      this.setFatTable()
    },
    fatData() {
      this.setFatTable()
    }
  },
  created() {
    this.gridOptions = {
      debug: false,
      columnDefs: [],
      rowData: [],
      overlayLoadingTemplate: "<span></span>",
      onVirtualRowRemoved: () => {
        if (this.gridApi) {
          this.postProcessGrid()
        }
      },
      onCellMouseOver: (params) => {
        try {
          let rowIndex = params.rowIndex
          const colIndex = params.colDef.index
          const highlighted = [...this.$refs.grid.$el.querySelectorAll("div.highlight")]
          highlighted.forEach((el) => el.classList.remove("highlight"))

          let cellInfo = params.data.cells[colIndex]

          if (!cellInfo) {
            return
          }
          const isRowSpanPart = cellInfo.data.cell.isRowSpanPart
          if (isRowSpanPart) {
            let bestIndex = -1
            let bestNode
            this.gridApi.forEachNode((rowNode, index) => {
              if (index > bestIndex && index < rowIndex && !rowNode.data.cells[colIndex].data.cell.isRowSpanPart) {
                bestIndex = index
                bestNode = rowNode
              }
            })
            cellInfo = bestNode.data.cells[colIndex]
            rowIndex = bestIndex
          }
          const rowSpan = cellInfo.rowSpan

          const rows = [...this.$refs.grid.$el.querySelectorAll("div.ag-row")]
          rows.filter((el) => {
            const currentRowIndex = Number(el.getAttribute("row-index"))
            return currentRowIndex >= rowIndex && currentRowIndex < rowIndex + rowSpan
          }).forEach((el) => {
            const bandCol = el.querySelector("div[col-id='band']")
            if (bandCol) {
              bandCol.classList.add("highlight")
            }
          })

        } catch (err) {
          console.error(err)
        }
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.resetColumnWidth)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resetColumnWidth)
    this.gridApi.destroy()
    this.gridApi = null
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
      this.setGridHeight()
      this.gridReady = true
    },
    setFatTable() {
      this.columnDefsInternal = []

      const widthsInfo = []
      let takenVector = 0
      let takenColumn = 0
      let numberOfLastLevelVectorsAndColumns = 0

      this.fatData.groups.forEach((group) => {
        group.vectors = []
        let colSpan = group.colSpan
        for (let i = 0; i < colSpan; i++) {
          for (let j = 0; j < colSpan;) {
            const vector = this.fatData.vectors[takenVector++]
            numberOfLastLevelVectorsAndColumns++
            vector.columns = []
            const vectorColSpan = vector.colSpan
            const vectorWidth = this.getWidthForVector(vector)

            if (vectorColSpan > 1) {
              numberOfLastLevelVectorsAndColumns--

              for (let k = 0; k < vectorColSpan; k++) {
                const column = this.fatData.columns[takenColumn]
                vector.columns.push(column)
                takenColumn++
                numberOfLastLevelVectorsAndColumns++
                if (vectorWidth) {
                  const value = parseInt(vectorWidth)
                  const unit = typeof vectorWidth == "string" ? (vectorWidth.includes("px") ? "px" : "%") : 0
                  widthsInfo.push({
                    id: column.data.Id,
                    width: Math.floor(value / vectorColSpan) + unit,
                    type: "column"
                  })
                }
              }
            } else if (vectorWidth) {
              widthsInfo.push({
                id: vector.data.Id,
                width: vectorWidth,
                type: "vector"
              })
            }

            group.vectors.push(vector)
            colSpan -= vector.colSpan
          }
        }
      })

      this.calculateWidths(widthsInfo)

      this.columnDefsInternal.push({
        autoHeight: true,
        resizable: true,
        headerName: "Band",
        field: "band",
        cellStyle: {
          "text-align": "center",
          "font-weight": "bold",
          "font-style": "italic"
        },
        width: 100
      })

      takenVector = 0
      this.fatData.groups.forEach((group) => {
        const topHeader = {
          headerName: group.data.Name,
          resizable: true,
          children: [],
          id: group.data.Id,
          headerClass() {
            if (!group.data.Style) return
            return `headerCell-${group.data.Style}`
          }
        }
        group.vectors.forEach((vector) => {
          let width = this.getWidthForVetorWithoutWidth(widthsInfo, numberOfLastLevelVectorsAndColumns)
          const result = widthsInfo.find((el) => el.id === vector.data.Id && el.type === "vector")

          if (result)
            width = result.width

          let vectorHeaderName = vector.data.Name
          if (this.isLoggedIn)
            vectorHeaderName += " " + vector.data.Version

          const vectorHeader = {
            headerName: vectorHeaderName,
            autoHeight: true,
            resizable: true,
            index: takenVector,
            cellRenderer: (params) => this.cellRenderer(params),
            cellStyle: (params) => {
              if (params.data.cells[params.colDef.index] && params.data.cells[params.colDef.index].data.cell.isRowSpanPart) {
                return {
                  "border-top": "none"
                }
              }
            },
            headerClass() {
              let ret = ""
              if (vector.data.Definition)
                ret += `headerCell-${vector.data.Definition.Style}`

              if (vector.data.State == 2)
                ret += " decorated"

              return ret
            },
            width: width
          }

          if (vector.columns.length)
            vectorHeader.children = []

          let columnTaken = false
          vector.columns.forEach((column) => {
            let width = this.getWidthForVetorWithoutWidth(widthsInfo, numberOfLastLevelVectorsAndColumns)
            const result = widthsInfo.find((el) => el.id === column.data.Id && el.type === "column")
            if (result) {
              width = result.width
            }

            const columnHeader = {
              autoHeight: true,
              resizable: true,
              headerName: column.data.Name,
              index: takenVector,
              headerClass() {
                if (!column.data.Definition) return
                return `headerCell-${column.data.Definition.Style}`
              },
              cellRenderer: (params) => this.cellRenderer(params),
              cellStyle(params) {
                const ret = {}
                if (params.data.cells[params.colDef.index]?.data.cell.isRowSpanPart) {
                  ret["border-top"] = "none"
                }
                if (params.data.cells[params.colDef.index]?.data.cell.isTopDotted) {
                  ret["border-top-style"] = "dashed"
                }
                if (params.data.cells[params.colDef.index]?.data.cell.isBottomDotted) {
                  ret["border-bottom-style"] = "dashed"
                }
                return ret
              },
              colSpan(params) {
                return params.data.cells[params.colDef.index]?.colSpan || 1
              },
              width: width
            }
            takenVector++
            columnTaken = true
            vectorHeader.children.push(columnHeader)
          })
          if (!columnTaken) {
            takenVector++
          }

          topHeader.children.push(vectorHeader)
        })

        this.columnDefsInternal.push(topHeader)
      })

      this.rowsProcessed = []
      //todo: calculate
      const cellsInfo = new Array(1000)

      this.fatData.rows.forEach((row) => {
        for (let i = 0; i < cellsInfo.length; i++) {
          if (cellsInfo[i] > 1) {
            row.cells.splice(i, 0, this.getEmptyCell())
            cellsInfo[i]--
          }
        }

        const cells = row.cells
        for (let i = 0; i < cells.length; i++) {
          if (cells[i].rowSpan > 1)
            cellsInfo[i] = cells[i].rowSpan
        }

        this.rowsProcessed.push(row)
      })

      this.gridApi.setRowData([])
      if (this.vectorsChanged)
        this.gridApi.setColumnDefs([])

      this.reInitGrid(this.vectorsChanged)
      this.vectorsChanged = false
    },
    calculateWidths(widthsInfo) {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      let availableWidth = screenWidth

      let scaleSum = 0
      widthsInfo.forEach((entity) => {
        if (typeof entity.width == "string") {
          let value = parseInt(entity.width)
          if (entity.width.includes("%")) {
            value = value * screenWidth / 100
          }

          if (availableWidth - value) {
            availableWidth -= value
          }
          entity.width = value
          entity.isFixed = true
        } else
          scaleSum += entity.width
      })

      widthsInfo.forEach((entity) => {
        if (!entity.isFixed) {
          entity.width = (entity.width / scaleSum) * availableWidth
        }
      })
    },
    getWidthForVetorWithoutWidth(widthsInfo, numberOfVectorsAndCols) {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      let availableWidth = screenWidth - 150 //band col width
      let sum = 0
      widthsInfo.forEach((info) => {
        sum += info.width
        numberOfVectorsAndCols--
      })

      availableWidth -= sum
      return Math.floor(availableWidth / numberOfVectorsAndCols)
    },
    getWidthForVector(vector) {
      const vectorId = vector.data.Definition.Id
      let ret = null

      this.vectors.forEach((optVector) => {
        optVector.Value.vectors.forEach((subOptVector) => {
          if (!ret && subOptVector.id == vectorId)
            ret = subOptVector.width
        })
      })

      return ret
    },
    cellRenderer(params) {
      const retDiv = document.createElement("div")
      if (params.data.cells[params.colDef.index]) {
        if (params.data.cells[params.colDef.index].data.wasTrimmed) {
          const wasWrappedDiv = document.createElement("i")
          wasWrappedDiv.innerHTML = `for ${params.data.cells[params.colDef.index].data.originalBand}:`
          wasWrappedDiv.style.color = "LimeGreen"
          retDiv.appendChild(wasWrappedDiv)
        }
        params.data.cells[params.colDef.index].data.cell.contents.forEach((content) => {
          const contentDiv = document.createElement("div")
          const span = document.createElement("span")
          const br = document.createElement("br")
          const separator = document.createElement("div")
          separator.setAttribute("class", "separator")
          if (content.format == "Link") {
            const link = document.createElement("a")
            link.setAttribute("target", "_blank")
            link.setAttribute("href", content.url)
            if (!content.isHighlighted) {
              span.innerHTML = content.urlText
              link.appendChild(span)
            } else {
              link.appendChild(texts.getHighlitedElement(content.urlText, this.filters.searchString))
            }
            contentDiv.appendChild(link)
            contentDiv.appendChild(br)
          } else if (content.format == "Separator") {
            contentDiv.appendChild(separator)
          } else {
            if (!content.isHighlighted) {
              span.innerHTML = content.content
              contentDiv.appendChild(span)
            } else {
              contentDiv.appendChild(texts.getHighlitedElement(content.content, this.filters.searchString))
            }
          }

          content.notes?.forEach((note) => {
            const link = document.createElement("a")
            link.setAttribute("target", "_blank")
            link.setAttribute("href", note.url)
            link.innerHTML = note.urlText
            contentDiv.appendChild(link)
          })

          if (params.data.cells[params.colDef.index].data.wasTrimmed) {
            contentDiv.style.color = "LimeGreen"
          }
          retDiv.appendChild(contentDiv)
        })
      }
      return retDiv.innerHTML
    },
    reInitGrid(changeHeaders) {
      if (changeHeaders)
        this.gridApi.setColumnDefs(this.columnDefsInternal)
      this.gridApi.setRowData(this.rowsProcessed)
      if (changeHeaders) {
        this.gridApi.sizeColumnsToFit()
      }
      this.gridApi.resetRowHeights()
      this.postProcessGridHeaders()
      this.postProcessGrid()
    },
    postProcessGrid() {
      const bands = [...this.$refs.grid.$el.querySelectorAll("div[col-id='band']")]
      bands.forEach((el) => {
        if (!el.innerHTML) {
          el.style["border-top"] = "none"
        }
      })
    },
    postProcessGridHeaders() {
      if (!this.fatData)
        return
      const cols = this.columnApi.getAllColumns() || []
      const groups = []
      const takenIds = []
      cols.forEach((col) => {
        if (col.parent) {
          if (takenIds.indexOf(col.parent.groupId) == -1) {
            groups.push(col.parent)
            takenIds.push(col.parent.groupId)
          }

          if (col.parent.parent) {
            if (takenIds.indexOf(col.parent.parent.groupId) == -1) {
              groups.push(col.parent.parent)
              takenIds.push(col.parent.parent.groupId)
            }
          }
        }
      })

      this.fatData.groups.forEach((group) => {
        groups.forEach((groupDef) => {
          if (groupDef.originalColumnGroup.colGroupDef.id != null 
          && groupDef.originalColumnGroup.colGroupDef.id == group.data.Id) {
            let name = group.data.Name
            if (this.isLoggedIn && group.data.Version) {
              name += " " + group.data.Version
            }
            groupDef.originalColumnGroup.colGroupDef.headerName = name
          }
        })
      })
      this.gridApi.refreshHeader()
    },
    getEmptyCell() {
      return {
        rowSpan: 1,
        data: {
          cell: {
            isRowSpanPart: true,
            contents: [{
              content: ""
            }]
          }
        }
      }
    },
    setGridHeight() {
      const gridTop = this.$refs.grid.$el.getBoundingClientRect().top
      this.gridHeight = `calc(100vh - ${gridTop}px)`
      if (this.gridApi)
        this.gridApi.resetRowHeights()

    },
    resetColumnWidth() {
      this.reInitGrid(true)
    },
    onVectorsChanged() {
      this.vectorsChanged = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep #grid {
  .ag-cell {
    white-space: normal;
    text-overflow: unset;
    line-height: 18px;
    padding-left: 4px;
    padding-right: 4px;
    word-wrap: break-word;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-family: "Roboto", sans-serif;
  }

  .ag-row:last-child {
    .ag-cell {
      border-bottom: 1px solid #ccc;
    }
  }

  .ag-cell-focus {
    outline: none;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  .ag-header {
    font: 600 12px "Roboto", Helvetica, Arial, sans-serif;
  }

  div.ag-cell {
    user-select: text;
  }

  div.highlight {
    background-color: #ddd;
  }

  div.ag-body-container {
    border-bottom: 1px solid #bbb;
  }

  .ag-row {
    border: none;
    background-color: transparent;
  }

  .ag-row-hover {
    background-color: transparent;
  }

  .ag-header-cell,
  .ag-header-group-cell {
    background-color: #0044cc;
    color: white;
  }

  .ag-header-cell::after,
  .ag-header-group-cell::after {
    height: 100%;
    margin-top: 0;
    top: 0;
  }

  .headerCell-Orange {
    background-color: #f89406;
  }

  .headerCell-Red {
    background-color: #bd362f;
  }

  .headerCell-Green {
    background-color: #51a351;
  }

  .headerCell-Blue {
    background-color: #0088cc;
  }

  .headerCell-Yellow {
    background-color: #edc951;
  }

  .headerCell-Gray {
    background-color: gray;
  }

  div.decorated {
    border: 3px solid #90ee90;
  }

  div.separator {
    height: 10px;
  }

  .highlightSearch {
    background-color: deepskyblue;
    color: white;
  }
}
</style>
