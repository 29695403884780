export const formatFrequency = function(freq) {
  const unit = getUnit(freq)
  let retVal = ""
  switch (unit) {
    case "Hz":
      retVal = freq.format(12, 3, " ", ".")
      break
    case "kHz":
      retVal = (freq / 1000).format(12, 3, " ", ".")
      break
    case "MHz":
      retVal = (freq / 1000000).format(12, 3, " ", ".")
      break
    case "GHz":
      retVal = (freq / 1000000000).format(12, 3, " ", ".")
      break
  }
  retVal = retVal.replace(/0*$/, "").replace(/\.$/, "")
      retVal += (" " + unit)
      return retVal
}

export const getHighlitedElement = function(content, searchString) {
  if (searchString == undefined)
    searchString = ""
  const regex = new RegExp(searchString, "gi")

  if (content && searchString && content.search(regex) != -1) {
    const spanContainerLength = "<span class =\"highlightSearch\"></span>".length
    const results = new Array()
    const searchStringLength = searchString.length
    while (regex.exec(content)) {
      results.push(regex.lastIndex)
    }

    for (let i = 0; i < results.length; i++) {
      const index = results[i] + i * spanContainerLength
      const fPartIncludingSearchString = content.slice(0, index)

      const fPart = fPartIncludingSearchString.slice(0, index - searchStringLength)
      const mPart = fPartIncludingSearchString.slice(fPartIncludingSearchString.length - searchStringLength)
      const sPart = content.slice(index)
      content = `${fPart}<span class="highlightSearch">${mPart}</span>${sPart}`
    }
  }

  const div = document.createElement("div")
  const span = document.createElement("span")
  span.innerHTML = content
  div.appendChild(span)
  return div
}

export const hightlightText = function(content) {
  const div = document.createElement("div")
  const span = document.createElement("span")
  span.innerHTML = content = `<span class="highlightSearch">${content}</span>`
  div.appendChild(span)
  return div
}

export const formatDate = function(d) {
  return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." + d.getFullYear()
}

export const getHeaderDisplayName = function(header) {
  return `${header.rirName} - ${header.nameEng}`
}

export const getHeaderMinFreq = function(header) {
  return Math.min(...header.bodies.map(b => Math.min(...b.frequencyRanges.map((r) => r.m_Item1))))
}

export const getHeaderMaxFreq = function(header) {
  return Math.max(...header.bodies.map(b => Math.max(...b.frequencyRanges.map((r) => r.m_Item2))))
}

export const getBodyDisplayName = function(headerName, body) {
  const headerNameDisplay = body.draftsGroupMember ? body.headerRirName : headerName
  return `${headerNameDisplay} - ${body.nb} - ${body.name}`
}

export const getBodyMinFreq = function(body) {
  return Math.min(...body.frequencyRanges.map(r => r.m_Item1))
}

export const getBodyMaxFreq = function(body) {
  return Math.max(...body.frequencyRanges.map(r => r.m_Item2))
}

const getUnit = function(freq) {
  if (freq < 100000) {
    return "Hz"
  } else if (freq < 10000000) {
    return "kHz"
  } else if (freq < 10000000000) {
    return "MHz"
  } else {
    return "GHz"
  }
}