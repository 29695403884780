<template>
  <!-- eslint-disable vue/no-v-html -->
  <tr v-show="visible" :class="{ filtered: body.filtered }">
    <td class="pl-4">
      <div class="d-flex align-items-center pl-3">
        <span class="caretContainer pr-1" @click="$emit('toggle-body')">
          <font-awesome-icon v-if="!body.expanded" icon="chevron-down" />
          <font-awesome-icon v-else icon="chevron-up" />
        </span>
        <b-form-checkbox :checked="body.selected" inline class="mr-0" @change="$emit('body-selected')" />
        <b-btn variant="link" :href="apiLink" target="_blank" class="p-0 m-0 text-left">
          {{ displayName }}
        </b-btn>
        <b-btn v-if="intranet" variant="link" size="sm" :href="detailsLink" class="ml-2 p-0" target="_blank">
          <b-img :src="detailsIcon" :height="20" :width="20" />
        </b-btn>
        <span v-if="body.isDraft" class="ml-1 draft font-weight-bold">
          <span v-if="body.privateDraft">INTERNAL DRAFT</span>
          <span v-else-if="body.publicDraft">DRAFT</span>
          <span v-else>PRE DRAFT</span>
        </span>
        <span v-else-if="body.state === 'Decorated'" class="ml-1 draft font-weight-bold">PRE DRAFT</span>
      </div>
    </td>
    <td>
      {{ freqDisplay }}
    </td>
    <td>
      {{ body.remarks }}
    </td>
    <td>
      {{ body.version }}
    </td>
    <td>{{ dateDisplay }}</td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex"

import * as texts from "@/services/texts.js"
import * as rirs from "@/services/rirs.js"

export default {
  props: {
    body: {
      type: Object,
      required: true
    },
    header: {
      type: Object,
      required: true
    },
    mode: {
      type: Boolean,
      default: false
    },
    selectionMode: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(["intranet"]),
    detailsIcon() {
      return require("@/assets/details-icon.png")
    },
    fixedForSelection() {
      return this.selectionMode == "selected" && (this.body.selected || this.body.anyPositionSelected)
    },
    visible() {
      return this.header.expanded && rirs.isBodyVisible(this.body, this.selectionMode, this.mode)
    },
    dateDisplay() {
      if (this.body.date) {
        const timeStamp = this.body.date.substring(6, 19)
        const d = new Date(timeStamp * 1)
        return texts.formatDate(d)
      }
      return null
    },
    displayName() {
      return texts.getBodyDisplayName(this.header.rirName, this.body)
    },
    apiLink() {
      const rirId = this.body.draftsGroupMember ? this.body.headerRirId : this.header.rirId
      return `api/RIR/${rirId}/${this.body.uid}`
    },
    detailsLink() {
      const rirName = this.body.draftsGroupMember ? this.body.headerRirName : this.header.rirName
      return `#/rir/${rirName}/${this.body.uid}`
    },
    freqMin() {
      return texts.getBodyMinFreq(this.body)
    },
    freqMax() {
      return texts.getBodyMaxFreq(this.body)
    },
    freqMinFormatted() {
      return texts.formatFrequency(this.freqMin)
    },
    freqMaxFormatted() {
      return texts.formatFrequency(this.freqMax)
    },
    freqDisplay() {
      return `${this.freqMinFormatted} - ${this.freqMaxFormatted}`
    }
  }
}
</script>

<style lang="scss" scoped>
.draft {
  color: red;
}
</style>
