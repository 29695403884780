<template>
  <!-- eslint-disable vue/no-v-html -->
  <tr v-show="visible">
    <td>
      <b-form-checkbox
        :checked="position.selected"
        inline
        class="mr-0"
        @change="$emit('position-selected')"
      >
        {{ index + 1 }}
      </b-form-checkbox>
    </td>
    <td>{{ position.positionName }}</td>
    <td>{{ position.description }}</td>
    <td>{{ position.comment }}</td>
  </tr>
</template>

<script>
export default {
  props: {
    position: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    selectionMode: {
      type: String,
      required: true
    }
  },
  computed: {
    visible() {
      return this.position.selected || this.selectionMode === "all"
    }
  }
}
</script>
