<template>
  <div>
    <div class="strips-container" :class="{'with-margin': mouseDownInfo != null}">
      <div v-show="applicationGroups.length" class="fat-chart-applications-stripe">
        <div class="d-flex">
          <div class="flex-fixed-width-item bordered">
            <div
              v-for="(input, index) in applicationGroups"
              :key="index"
              class="pl-4 d-flex align-items-center app-name"
              :style="{'height': `${singleHeight}px`}"
            >
              {{ input.applicationName }}
            </div>
          </div>
          <div class="flex-shrink-1 flex-grow-0 p-0">
            <div ref="container" class="stripe-container" />
          </div>
          <div
            class="info-tooltip p-2 shadow"
            :style="{'top': tooltipTop, 'left' : tooltipLeft}"
          >
            {{ mouseOverInfoDisplay }}
          </div>
        </div>
      </div>
      <h4 v-if="!applicationGroups.length" class="text-center pt-5 mt-4">
        No results
      </h4>
    </div>
    <fat-chart-applications-info :info="mouseDownInfo" @close="mouseDownInfo = null" />
  </div>
</template>

<script>
import * as d3 from "d3"
import * as texts from "@/services/texts.js"

import FatChartApplicationsInfo from "@/components/FatChartApplicationsInfo.vue"

export default {
  components: {
    FatChartApplicationsInfo
  },
  props: {
    fatData: {
      type: Object,
      default: () => null
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    applications: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      container: null,
      mouseDownInfo: null,
      zoom: null,
      tooltipX: null,
      tooltipY: null,
      mouseOverInfo: null,
      singleHeight: 0
    }
  },
  computed: {
    applicationGroups() {
      const grouped = []

      this.fatData.applications
        .map(a => a.application)
        .filter((v, i, a) => a.indexOf(v) === i)
        .forEach(applicationName => {
          grouped.push({
            applicationName,
            applications: this.fatData.applications.filter(ap => ap.application === applicationName)
          })
        })
      return grouped
    },
    mergedData() {
      let allMerged = []

      this.applicationGroups.forEach((input, index) => {
        const color = this.applications.find(a => a.code === input.applicationName)?.color || "000000000"
        const ret = input.applications.map(application => (
          {
            part: 0,
            partsCount: 1,
            data: application,
            color: `#${color.substring(3, 9)}`, //todo: convert alpha too
            index
          }
        ))

        const merged = this.mergeArray(ret)
        allMerged = [...allMerged, ...merged]
      })

      this.setPlacements(allMerged)
      let ret = []

      allMerged.forEach(o => { ret = [...ret, ...o.all] })

      return ret
    },
    tooltipTop() {
      return `${(this.tooltipY || 0) + 10}px`
    },
    tooltipLeft() {
      return `${(this.tooltipX || 0) + 10}px`
    },
    mouseOverInfoDisplay() {
      if (!this.mouseOverInfo) {
        return
      }
      return this.formatFrequency(this.mouseOverInfo.data)
    },
    minFreq() {
      return Math.min(...this.fatData.applications.map(a => a.lowerFrequency))
    },
    maxFreq() {
      return Math.max(...this.fatData.applications.map(a => a.upperFrequency))
    }
  },
  watch: {
    fatData() {
      this.initChart()
    }
  },
  mounted() {
    window.addEventListener("resize", this.initChart)
    this.initChart()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initChart)
  },
  methods: {
    checkOverlapping(item1, item2) {
      return !(item1.data.upperFrequency <= item2.data.lowerFrequency
        || item1.data.lowerFrequency >= item2.data.upperFrequency)
    },
    getAnyOverlapping(array) {
      let ret = null
      array.forEach(a => {
        if (ret) {
          return
        }
        array.forEach(b => {
          if (ret) {
            return
          }
          if (a !== b && this.checkOverlapping(a, b)) {
            ret = { a, b }
          }
        })
      })

      return ret
    },
    mergeArray(input) {
      const ret = [...input]
      let flag = true

      while (flag) {
        const anyOverlapping = this.getAnyOverlapping(ret)

        if (!anyOverlapping) {
          flag = false
        } else {
          const merged = { ...anyOverlapping.a }

          if (!merged.all) {
            merged.all = [anyOverlapping.a, anyOverlapping.b]
          } else if (anyOverlapping.b.all) {
            merged.all = [...merged.all, ...anyOverlapping.b.all]
          } else {
            merged.all = [...merged.all, anyOverlapping.b]
          }

          merged.data.lowerFrequency = Math.min(anyOverlapping.a.data.lowerFrequency, anyOverlapping.b.data.lowerFrequency)
          merged.data.upperFrequency = Math.max(anyOverlapping.a.data.upperFrequency, anyOverlapping.b.data.upperFrequency)
          const index1 = ret.indexOf(anyOverlapping.a)
          const index2 = ret.indexOf(anyOverlapping.b)
          ret[index1] = merged
          ret.splice(index2, 1)
        }
      }

      return ret
    },
    setPlacements(array) {
      array.forEach(o => {
        if (!o.all)
          o.all = [o]


        o.all
          .forEach(application => {
            const overlappings = o.all
              .filter(otherApplication => otherApplication !== application && this.checkOverlapping(otherApplication, application))

            const overlappingPlaced = overlappings.filter(o => o.placementIndex != null)
            if (overlappingPlaced.length) {
              const overlappingIndexes = overlappingPlaced.map(o => o.placementIndex)
              const maxIndex = Math.max(...overlappingIndexes)
              let lowestAvailable = maxIndex + 1
              for (let i = maxIndex; i >= 0; i--) {
                if (lowestAvailable > i && !overlappingIndexes.includes(i)) {
                  lowestAvailable = i
                }
              }
              application.placementIndex = lowestAvailable
            } else {
              application.placementIndex = 0
            }
          })

        o.all.forEach(a => { a.placementIndex = a.placementIndex || 0 })
        const placementIndexMax = Math.max(...o.all.map(i => i.placementIndex))
        o.all.forEach(a => { a.placementIndexMax = placementIndexMax })
      })
    },
    async initChart() {
      await this.$nextTick()
      const thisRef = this
      const chartTop = this.$refs.container.getBoundingClientRect().top
      const height = window.innerHeight - chartTop - 5
      const width = window.innerWidth - 200
      this.singleHeight = height / this.applicationGroups.length

      const rMin = this.rescale(this.minFreq)
      const rMax = this.rescale(this.maxFreq)

      const x = d3.scaleLinear().domain([rMin, rMax]).range([0, width])
      const y = d3.scaleLinear().domain([0, 1]).range([0, this.singleHeight])

      d3.select(this.$refs.container).selectAll("*").remove()

      this.zoom = d3.zoom().filter(() => {
        if (d3.event.type === "wheel") {
          return d3.event.ctrlKey
        }
        return true
      }).on("zoom", () => {
        this.container.attr("transform", d3.event.transform)
      })

      this.container = d3.select(this.$refs.container)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .call(this.zoom)
        .append("g")

      this.container.selectAll("rect")
        .data(this.mergedData)
        .enter()
        .append("svg:rect")
        .attr("x", (d) => x(this.rescale(d.data.lowerFrequency)))
        .attr("y", (d) => d.index * this.singleHeight + y(d.placementIndex / (d.placementIndexMax + 1)))
        .attr("height", (d) => y(1 / (d.placementIndexMax + 1)))
        .attr("width", (d) => x(this.rescale(d.data.upperFrequency)) - x(this.rescale(d.data.lowerFrequency)))
        .attr("fill", (d) => d.color)
        .on("mouseover", function (d) { thisRef.mouseOver(d, this) })
        .on("mouseout", function (d) { thisRef.mouseOut(d, this) })
        .on("click", function (d) { thisRef.mouseClick(d, this) })

      this.applicationGroups.forEach((i, index) => {
        this.container.append("line")
          .style("stroke", "#ccc")
          .attr("x1", 0)
          .attr("x2", width)
          .attr("y2", index * this.singleHeight)
          .attr("y1", index * this.singleHeight)
      })
    },
    rescale(dim) {
      if (dim === 0)
        return 0

      return Math.log(dim) / Math.log(10)
    },
    mouseOver(d, element) {
      this.tooltipX = d3.event.pageX
      this.tooltipY = d3.event.pageY
      d3.select(element).attr("fill", "black")
      this.mouseOverInfo = d
    },
    mouseOut(d, element) {
      if (this.mouseDownInfo !== d) {
        d3.select(element).attr("fill", (d) => d.color)
      }
      this.tooltipX = this.tooltipY = null
      this.mouseOverInfo = null
    },
    mouseClick(d, element) {
      d3.selectAll("rect")
        .each(function () {
          d3.select(this).attr("fill", (d) => d.color)
        })
      if (this.mouseDownInfo === d) {
        this.mouseDownInfo = null
        return
      }
      this.mouseDownInfo = d

      d3.select(element).attr("fill", "black")
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth", block: d.index < 4 ? "center" : "start" })
      }, 500)
    },
    formatFrequency(application) {
      return `${texts.formatFrequency(application.lowerFrequency)} - ${texts.formatFrequency(application.upperFrequency)}`
    },
    resetZoom() {
      this.initChart()
      //below both work but after zooming again old state is persisted...
      //this.zoom.transform(this.container, d3.zoomIdentity)
      //this.container.transition()
      //.duration(750)
      //.call(this.zoom.transform, d3.zoomIdentity.scale(1))
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep rect {
  cursor: pointer;
}

.strips-container {
  &.with-margin {
    margin-bottom: 300px;
  }

  .fat-chart-applications-stripe {
    border-top: 1px solid #ccc;

    &:last-child {
      border-bottom: 1px solid #ccc;
    }
  }
}

.flex-fixed-width-item {
  flex: 0 0 200px;

  .app-name {
    border-bottom: 1px solid #ccc;
  }
}

.bordered {
  border-right: 1px solid #ccc;
}

::v-deep rect {
  cursor: pointer;
  stroke: white;
}

.info-tooltip {
  border-radius: 5px;
  position: fixed;
  background: white;
  z-index: 999;
}

</style>
