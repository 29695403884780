export const getRirs = (params) => findHeaders(params)

export const getRirBody = (id, mode) => {
    const header = window.rirsOfflineData.data.Headers.find(h => h.bodies.find(b => b.uid === id))

    if (!header) {
      return null
    }
    
    const body = header.bodies.find(b => b.uid === id)

    return mode ? body.htmlWithChanges : body.html
}

const findHeaders = function (findRequest) {
  const retVal = {}
  let allHeaders = window.rirsOfflineData.data.Headers

  if (findRequest.lowerFreq || findRequest.upperFreq)
    allHeaders = filterUsingFrequencies(allHeaders, findRequest.lowerFreq, findRequest.upperFreq, findRequest.filteringMode)

  if (findRequest.hideEmptyHeaders) {
    allHeaders = allHeaders.filter(h => h.bodies.length !== 0)
  }

  retVal.data = filterHeaders(allHeaders, findRequest.searchString)

  if (findRequest.omitBodies) {
    retVal.data.Headers.forEach(h => h.bodies = null)
    retVal.data.ItemsFound = retVal.data.ItemsFound.filter(b => !b.includes("bodies["))
  }
  else if (findRequest.omitPositions) {
    retVal.data.Headers.forEach(h => h.bodies.forEach(b => b.positions = null))
    retVal.data.ItemsFound = retVal.data.ItemsFound.filter(b => !b.includes("positions["))
  }

  retVal.isOk = true

  return retVal
}

const filterUsingFrequencies = function (headers, lowerFreq, upperFereq, filteringMode) {
  for (const oneHeader of headers) {
    for (const oneBody of oneHeader.bodies) {
      let bodyShouldStay = false

      const minFreq = Math.min.apply(Math, oneBody.frequencyRanges.map(f => f.m_Item1))
      const maxFreq = Math.max.apply(Math, oneBody.frequencyRanges.map(f => f.m_Item2))

      if (filteringMode.includes(FrequencyFilteringMode.InsideAndBefore)) {
        if (lowerFreq)
          if (maxFreq >= lowerFreq && minFreq <= lowerFreq)
            bodyShouldStay = true
      }

      if (filteringMode.includes(FrequencyFilteringMode.InsideAndAfter)) {
        if (upperFereq)
          if (minFreq <= upperFereq && maxFreq >= upperFereq)
            bodyShouldStay = true
      }

      if (filteringMode.includes(FrequencyFilteringMode.InsideOnly)) {
        if (lowerFreq && upperFereq)
          if (minFreq >= lowerFreq && maxFreq <= upperFereq)
            bodyShouldStay = true
      }


      if (bodyShouldStay) {
        oneHeader.filtered = RirFilteredState.Yes
        oneBody.filtered = RirFilteredState.Yes
      }
    }
  }

  return headers
}

const filterHeaders = function (headers, searchString) {
  const retVal = {
    Headers: [],
    ItemsFound: []
  }

  for (const oneHeader of headers) {
    retVal.Headers.push(oneHeader)
  }

  if (!searchString || searchString === "") {
    return retVal
  }

  const stringToSearch = searchString.toLowerCase()

  for (const oneHeader of retVal.Headers) {
    let foundInPaths = []
    const propsInHeader = matchPropertiesInObject(oneHeader, stringToSearch)
    if (propsInHeader.length > 0) {
      oneHeader.filtered = RirFilteredState.Yes
      foundInPaths = foundInPaths.concat(propsInHeader)
    }

    for (const oneBody of oneHeader.bodies) {
      const propsInBody = matchPropertiesInObject(oneBody, stringToSearch)
      if (propsInBody.length > 0) {
        oneHeader.filtered = RirFilteredState.Yes
        oneBody.filtered = RirFilteredState.Yes
        for (const oneProp of propsInBody)
          foundInPaths.push(`bodies[${oneHeader.bodies.indexOf(oneBody)}].${oneProp}`)
      }

      for (const onePosition of oneBody.positions) {
        const propsInPosition = matchPropertiesInObject(onePosition, stringToSearch)
        if (propsInPosition.length > 0) {
          oneHeader.filtered = RirFilteredState.Yes
          oneBody.filtered = RirFilteredState.Yes
          for (const oneProp of propsInPosition)
            foundInPaths.push(`bodies[${oneHeader.bodies.indexOf(oneBody)}].positions[${oneBody.positions.indexOf(onePosition)}].${oneProp}`)
        }
      }
    }

    if (oneHeader.filtered == RirFilteredState.Yes) {
      for (const onePath of foundInPaths)
        retVal.ItemsFound.push(`headers[${retVal.Headers.indexOf(oneHeader)}].${onePath}`)
    }
  }


  return retVal
}

const matchPropertiesInObject = function (objectToSearch, stringToSearch) {
  const retVal = []

  Object.keys(objectToSearch).forEach((oneProp) => {
    const valueOfProperty = objectToSearch[oneProp]
    const stringValue = (valueOfProperty || "").toString()
    if (stringValue != null && stringValue.toLowerCase().includes(stringToSearch))
      retVal.push(oneProp)
  })

  return retVal
}

const RirFilteredState = {
  Yes: 1,
  No: 0
}

const FrequencyFilteringMode = {
  InsideOnly: 0,
  InsideAndBefore: 1,
  InsideAndAfter: 2
}