<template>
  <div id="chart-container">
    <div
      ref="canvas"
      style="width: 70%; height: 60%;"
    >
      <span>There is no renderer attached. Please click Search.</span>
    </div>
    <b-card
      ref="infoBox"
      class="infoBoxStyle"
      style="width: 350px; height: auto;"
    >
      <div
        v-if="infoBoxDataContext"
        class="bg-secondary p-2"
        style="font-weight: bold;"
      >
        {{ formatFrequency(infoBoxDataContext.currentBand.minFreq) }} -
        {{ formatFrequency(infoBoxDataContext.currentBand.maxFreq) }}
        <span
          v-if="infoBoxDataContext.currentServiceInfo && infoBoxDataContext.currentServiceInfo.vectorName"
        >{{ infoBoxDataContext.currentServiceInfo.vectorName }}</span>
        <span v-else>-</span>
      </div>
      <div
        v-if="infoBoxDataContext"
        class="panel-body"
      >
        <div
          v-if="infoBoxDataContext.serviceInfo"
          style="font-weight: bold;"
        >
          {{ infoBoxDataContext.serviceInfo.Name }}
        </div>
        <ul
          v-if="infoBoxDataContext.currentServiceInfo"
          class="list-group"
        >
          <li
            class="list-group-item"
            style="word-wrap: break-word;"
          >
            Applications:
            <b-badge>{{ infoBoxDataContext.currentServiceInfo.applications.length }}</b-badge>
            <br>
            <span
              v-for="(application, index) in infoBoxDataContext.currentServiceInfo.applications"
              :key="index"
              class="label label-info"
              style="margin-right: 5px; white-space: normal;"
            >{{ application }}</span>
          </li>
          <li class="list-group-item">
            Beneficiary:
            <b-badge>{{ infoBoxDataContext.currentServiceInfo.beneficiary.length }}</b-badge>
            <br>
            <span
              v-for="(benef, index) in infoBoxDataContext.currentServiceInfo.beneficiary"
              :key="index"
              class="label label-info"
              style="margin-right: 5px; white-space: normal;"
            >{{ benef }}</span>
          </li>
        </ul>
      </div>
    </b-card>
    <div
      id="legendBox"
      class="infoBoxStyleBottom chartLegend"
      style="height: 50px;"
    >
      <span
        v-for="service in legendServices"
        :key="service.Id"
        style="break-inside: avoid; margin-left: 15px; min-width: 200px;"
        class="hoverable"
        @mouseenter="selectService(service.Id)"
        @mouseleave="deSelectService"
      >
        <span
          style="width: 18px; height: 18px;"
          :style="{'background-color': service.Color}"
        >&nbsp;</span>
        <span style="margin-left: 5px;">{{ service.Name }}</span>
        ({{ service.HowMany }})
      </span>
    </div>
  </div>
</template>


<script>
import * as THREE from "three"
import optimerFont from "@/services/fonts.js"
import * as texts from "@/services/texts.js"

export default {
  props: {
    fatData: {
      type: Object,
      default: () => null
    },
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      container: null,
      camera: null,
      scene: null,
      renderer: null,
      group: null,
      set1Group: null,
      set2Group: null,
      raycaster: null,
      mouse: null,
      loader: new THREE.FontLoader(),
      fontLoader: null,
      modelLoader: null,
      objects: [],
      selectedObject: null,
      previousColor: null,
      targetXPosition: 0,
      targetXPositionOnMouseDown: 0,
      mouseX: 0,
      mouseY: 0,
      mouseXOnMouseDown: 0,
      windowHalfX: 0,
      windowHalfY: 0,
      isMouseDown: false,
      keepRendering: false,
      infoBoxInfo: {
        x: 0,
        y: 0,
        targetX: 0,
        targetY: 0,
        opacity: 0,
        targetOpacity: 0
      },
      cameraInfo: {
        z: 400,
        targetZ: 500,
        set1RotateTarget: 0,
        set2RotateTarget: 0,
      },
      extrudeSettings: {
        depth: 30,
        bevelEnabled: false,
        bevelSegments: 2,
        steps: 2,
        bevelSize: 5,
        bevelThickness: -2
      },
      graphSettings: {
        use3D: true,
        minFreq: 4000,
        maxFreq: 300000000000,
        chartWidth: 1000,
        chartHeight: 200,
        labelHeight: 50,
        axisHeight: 50,
        setHeight: 150,
        padding: 20,
        markedFrequencies: [0, 8300, 30000, 300000, 3000000, 30000000, 300000000, 3000000000, 300000000000]
      },
      legendServices: [],
      allServices: {},
      infoBoxDataContext: null,
      timeoutHandler: null,
      animationFrameHandler: null
    }
  },
  watch: {
    fatData() {
      this.initChart()
    }
  },
  mounted() {
    this.initChart()
  },
  beforeDestroy() {
    clearTimeout(this.timeoutHandler)
    cancelAnimationFrame(this.animationFrameHandler)
    this.clearContext()
  },
  methods: {
    resetView() {
      this.cameraInfo.targetZ = 400
      this.targetXPosition = 0
    },
    clearContext() {
      this.scene?.dispose()
      this.renderer?.dispose()
      this.container = null
      this.camera = null
      this.scene = null
      this.renderer = null
      this.group = null
    },
    addLine(lineColor, x1, y1, x2, y2) {
      const material = new THREE.LineBasicMaterial({
        color: lineColor
      })
      const geometry = new THREE.Geometry()
      geometry.vertices.push(new THREE.Vector3(x1, y1, 0))
      geometry.vertices.push(new THREE.Vector3(x2, y2, 0))
      const line = new THREE.Line(geometry, material)
      this.group.add(line)
    },
    addShape(shape, color, x, y, z, rx, ry, rz, s, context, no3D, addTo) {
      context = context || null
      no3D = no3D || false
      addTo = addTo || null

      let geometry = {}

      if (this.graphSettings.use3D == false || no3D == true) {
        geometry = new THREE.ShapeBufferGeometry(shape)
      } else {
        geometry = new THREE.ExtrudeGeometry(shape, this.extrudeSettings)
      }

      const emmisiveColor = new THREE.Color(0.45, 0.45, 0.45)

      const mesh = new THREE.Mesh(geometry, new THREE.MeshStandardMaterial({
        color: color,
        emissive: emmisiveColor,
        side: THREE.FrontSide,
        depthTest: true,
        roughness: 0.9,
        metalness: 0.1,
        fog: false
      }))
      mesh.userData = context
      mesh.position.set(x, y, z)
      mesh.rotation.set(rx, ry, rz)
      mesh.scale.set(s, s, s)
      if (addTo == null) {
        this.group.add(mesh)
      } else {
        addTo.add(mesh)
      }

      if (context != null)
        this.objects.push(mesh)

      return mesh
    },
    addText(x, y, font, text, angle, size, addTo) {
      angle = angle || 0
      size = size || 13
      addTo = addTo || null

      const material = new THREE.MeshBasicMaterial({
        color: 0x000000
      })
      const geometry = new THREE.TextGeometry(text, {
        font: font,
        size: size,
        height: 0.001,
        curveSegments: 12,
        bevelEnabled: false
      })

      const text2 = new THREE.Mesh(geometry, material)
      if (this.graphSettings.use3D == false)
        text2.position.set(x + 5, y + 5, 0)
      else
        text2.position.set(x + 5, y + 5, 0)

      text2.rotation.set(0, 0, angle)
      //text.scale.y = 0.8;
      if (addTo == null) {
        this.group.add(text2)
      } else {
        addTo.add(text2)
      }
      return text2
    },
    addFreqCircle(x, y, r) {
      const geometry = new THREE.CircleGeometry(r, 6)
      const material = new THREE.MeshBasicMaterial({
        color: 0x000000
      })
      const circle = new THREE.Mesh(geometry, material)
      circle.position.set(x, y, 0)
      this.group.add(circle)
    },
    addRectangle(x, y, width, height) {
      const rectShape = new THREE.Shape()
      rectShape.moveTo(x, y)
      rectShape.lineTo(x, y + height)
      rectShape.lineTo(x + width, y + height)
      rectShape.lineTo(x + width, y)
      rectShape.lineTo(x, y)
      return rectShape
    },
    formatFrequency(freq) {
      return texts.formatFrequency(freq)
    },
    selectService(idService) {
      this.objects.forEach(function (entry) {
        if (entry.userData != null && entry.userData.serviceInfo != null && entry.userData.serviceInfo.Id != idService) {
          entry.visible = false
        }
      })
    },
    deSelectService() {
      this.objects.forEach(function (entry) {
        entry.visible = true
      })
    },
    clearChilds(element) {
      if (element != null) {
        while (element.children.length > 0) {
          let toRemoveRef = element.children[0]
          element.remove(toRemoveRef)
          //this.renderer.deallocateObject(toRemoveRef);
          if (toRemoveRef.geometry != null && toRemoveRef.geometry != undefined)
            toRemoveRef.geometry.dispose()
          if (toRemoveRef.material != null && toRemoveRef.geometry != undefined)
            toRemoveRef.material.dispose()
          toRemoveRef = undefined
          //delete (toRemoveRef);
        }
      }
    },
    clearChart() {
      while (this.objects.length) {
        this.objects.pop()
      }

      this.clearChilds(this.set1Group)
      this.clearChilds(this.set2Group)
      if (this.group != null) {
        this.clearChilds(this.group)
        this.group.add(this.set1Group)
        this.group.add(this.set2Group)
      }
    },
    onDocumentMouseMove(event) {
      if (!this.keepRendering)
        return false

      this.mouse.x = (event.clientX / this.renderer.domElement.clientWidth) * 2 - 1
      this.mouse.y = -((event.clientY - this.container.offsetTop) / this.renderer.domElement.clientHeight) * 2 + 1
      this.mouseX = event.clientX - this.windowHalfX
      this.mouseY = event.clientY - this.windowHalfY


      if (this.isMouseDown) {
        this.targetXPosition = this.targetXPositionOnMouseDown + (this.mouseX - this.mouseXOnMouseDown)
      }


      this.raycaster.setFromCamera(this.mouse, this.camera)
      const intersects = this.raycaster.intersectObjects(this.objects)

      if (intersects.length > 0) {
        if (typeof intersects[0].object.userData == "string") {
          if (intersects[0].object.userData == "SET1") {
            this.cameraInfo.set1RotateTarget = 1.5708
          } else if (intersects[0].object.userData == "SET2") {
            this.cameraInfo.set2RotateTarget = 1.5708
          }
          if (this.selectedObject != null) {
            if (this.previousColor != null) {
              this.selectedObject.object.material.color.setHex(this.previousColor)
              //this.selectedObject.object.position.z = 0;
            }
          }
          this.selectedObject = null
          this.previousColor = null
          this.infoBoxInfo.targetOpacity = 0
        } else {
          if (this.selectedObject == null || (this.selectedObject.object.id != intersects[0].object.id)) {
            if (this.selectedObject != null) {
              if (this.previousColor != null) {
                this.selectedObject.object.material.color.setHex(this.previousColor)
                //this.selectedObject.object.position.z = 0;
              }
            }

            this.selectedObject = intersects[0]
            this.previousColor = this.selectedObject.object.material.color.getHex()
            this.selectedObject.object.material.color.setRGB(1, 0, 0)
            this.infoBoxDataContext = this.selectedObject.object.userData

            this.infoBoxInfo.targetOpacity = 0.95
            this.cameraInfo.set1RotateTarget = 0
            this.cameraInfo.set2RotateTarget = 0
          }
        }
        this.infoBoxInfo.targetX = event.clientX + 10
        this.infoBoxInfo.targetY = event.clientY + 10
      } else {
        if (this.selectedObject != null) {
          if (this.previousColor != null) {
            this.selectedObject.object.material.color.setHex(this.previousColor)
            //this.selectedObject.object.position.z = 0;
          }
        }
        this.selectedObject = null
        this.previousColor = null
        this.infoBoxInfo.targetOpacity = 0
        this.cameraInfo.set1RotateTarget = 0
        this.cameraInfo.set2RotateTarget = 0
      }

      return false
    },
    initChart() {
      this.clearContext()
      if (this.container == null) {
        this.container = this.$refs.canvas
        this.windowHalfX = this.container.clientWidth / 2
        this.windowHalfY = this.container.clientHeight / 2
      }

      this.legendServices = this.fatData.services // From AJAX should come

      if (this.raycaster == null)
        this.raycaster = new THREE.Raycaster()

      if (this.mouse == null)
        this.mouse = new THREE.Vector2()

      this.fatData.services.forEach((service) => {
        this.allServices[service.Id] = service
      })

      if (this.scene == null) {
        this.scene = new THREE.Scene()

        const renderWidth = (window.innerWidth * 0.98) / 2
        const renderHeight = ((window.innerHeight - 150) * 0.98) / 2

        this.graphSettings.chartWidth = renderWidth * 1.5
        this.graphSettings.chartHeight = renderHeight * 1.2
        this.graphSettings.setHeight = this.graphSettings.chartHeight * 0.35

        //this.camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 1, 2000);
        //this.camera.position.set(0, -20, 1000);

        this.camera = new THREE.OrthographicCamera(-renderWidth, renderWidth, renderHeight, -renderHeight, 1, 2000)
        this.camera.position.set(0, -20, 500)

        this.camera.scale.x = 0.85
        this.camera.scale.y = 0.85
        this.scene.add(this.camera)

        const light = new THREE.PointLight(0xffffff, 0.2)
        light.position.set(1, -200, 100).normalize()
        this.camera.add(light)

        const dirLight = new THREE.DirectionalLight(0xffffff, 0.4)
        dirLight.position.set(100, 0, 1000).normalize()
        this.scene.add(dirLight)

        const ambientLight = new THREE.AmbientLight(0x101010)
        this.scene.add(ambientLight)

        //var light = new THREE.PointLight(0xffffff, 0.9);
        //this.camera.add(light);

        this.group = new THREE.Group()
        this.group.position.y = 0
        this.group.position.x = 0
        this.scene.add(this.group)

        this.set1Group = new THREE.Group()
        this.set1Group.position.y = 0
        this.set1Group.position.x = 0
        this.set1Group.position.z = 200
        this.group.add(this.set1Group)

        this.set2Group = new THREE.Group()
        this.set2Group.position.y = 0
        this.set2Group.position.x = 0
        this.set2Group.position.z = 200
        this.group.add(this.set2Group)

      }

      const font = this.loader.parse(optimerFont)

      let currentSet = this.fatData.sets.find(function (element) {
        return element.name == "Primary"
      })

      let minDataFreq = 900719925474099
      let maxDataFreq = 0

      if (this.fatData.sets[0].bands.length > 0 || this.fatData.sets[1].bands.length > 0) {
        this.fatData.sets[0].bands.forEach(function (element) {
          minDataFreq = Math.min(minDataFreq, element.minFreq)
          maxDataFreq = Math.max(maxDataFreq, element.maxFreq)
        })
        this.fatData.sets[1].bands.forEach(function (element) {
          minDataFreq = Math.min(minDataFreq, element.minFreq)
          maxDataFreq = Math.max(maxDataFreq, element.maxFreq)
        })
      } else {
        minDataFreq = this.filters.lowerFreq * this.filters.selectedLowerFreq
        maxDataFreq = this.filters.upperFreq * this.filters.selectedUpperFreq
      }

      const frequencyAxisMarks = []

      frequencyAxisMarks.push(minDataFreq)

      for (let i = 0; i < this.graphSettings.markedFrequencies.length; i++) {
        const frequencyToDraw = this.graphSettings.markedFrequencies[i]
        if (frequencyToDraw > minDataFreq && frequencyToDraw < maxDataFreq) {
          frequencyAxisMarks.push(frequencyToDraw)
        }
      }

      frequencyAxisMarks.push(maxDataFreq)

      if (frequencyAxisMarks.length >= 1) {

        const minAxisFreq = frequencyAxisMarks[0]
        const maxAxisFreq = frequencyAxisMarks[frequencyAxisMarks.length - 1]

        const maxSetWidth = this.graphSettings.chartWidth
        const freqToPixelCooficient = maxSetWidth / (maxAxisFreq.log() - minAxisFreq.log())
        const offsetMinusX = (maxAxisFreq.log() - (maxAxisFreq.log() - minAxisFreq.log()) / 2)

        this.group.position.x = offsetMinusX

        // axis drawing
        let minAxisX = 900719925474099
        let maxAxisX = 0

        const axisColor = new THREE.Color(0.2, 0.2, 0.2)
        const logAxisColor = new THREE.Color(0.7, 0.7, 0.7)

        const setBackgroundColor = new THREE.Color(0.95, 0.95, 0.95)
        const setBackgroundColor2 = new THREE.Color(0.90, 0.90, 0.90)

        const chartUpperBorder = this.graphSettings.chartHeight / 2
        const chartBottomBorder = -this.graphSettings.chartHeight / 2

        for (let i = 0; i < frequencyAxisMarks.length; i++) {
          const frequencyToDraw = frequencyAxisMarks[i]
          const frequencyPosition = frequencyToDraw.log()
          const currentAxisX = frequencyPosition
          minAxisX = Math.min(minAxisX, currentAxisX)
          maxAxisX = Math.max(maxAxisX, currentAxisX)

          const rectMark = this.addRectangle(-1, 0, 3, 10)
          this.addShape(rectMark, axisColor, (currentAxisX - offsetMinusX) * freqToPixelCooficient - 1.5, chartUpperBorder, 0, 0, 0, 0, 1, null, true)
          this.addShape(rectMark, axisColor, (currentAxisX - offsetMinusX) * freqToPixelCooficient - 1.5, chartBottomBorder - 10, 0, 0, 0, 0, 1, null, true)

          let labelYOffset = 0
          if (i % 2 != 0)
            labelYOffset = -15

          this.addText((currentAxisX - offsetMinusX) * freqToPixelCooficient, chartBottomBorder - 25 + labelYOffset, font, this.formatFrequency(frequencyToDraw), 0, 9)
          if (frequencyAxisMarks != null)
            if (i + 1 < frequencyAxisMarks.length) {
              const endFrequencyToDraw = frequencyAxisMarks[i + 1]
              const endFrequencyPosition = endFrequencyToDraw.log()

              const currentStartX = (currentAxisX - offsetMinusX) * freqToPixelCooficient
              const currentEndX = (endFrequencyPosition - offsetMinusX) * freqToPixelCooficient

              const bandBackgroundRect = this.addRectangle(currentStartX, chartBottomBorder, currentEndX - currentStartX, this.graphSettings.chartHeight)
              const bandUserData = {
                "currentBand": {
                  minFreq: frequencyToDraw,
                  maxFreq: endFrequencyToDraw
                },
                "currentServiceInfo": null,
                "serviceInfo": null
              }


              if (i % 2 != 0) {
                const bandBackground = this.addShape(bandBackgroundRect, setBackgroundColor, 0, 0, -1, 0, 0, 0, 1, bandUserData, true)
                bandBackground.material.transparent = true
                bandBackground.material.opacity = 0.25
              } else {
                const bandBackground = this.addShape(bandBackgroundRect, setBackgroundColor2, 0, 0, -1, 0, 0, 0, 1, bandUserData, true)
                bandBackground.material.transparent = true
                bandBackground.material.opacity = 0.25
              }
            }
        }

        const logLinesCount = 30
        const freqPerCount = (maxAxisFreq - minAxisFreq) / logLinesCount
        let startLogFreq = minAxisFreq
        for (let i = 0; i <= logLinesCount; i++) {
          const lineX = (startLogFreq.log() - offsetMinusX) * freqToPixelCooficient
          this.addLine(logAxisColor, lineX, chartUpperBorder, lineX, chartBottomBorder)
          startLogFreq += freqPerCount
        }

        const sminAxisX = (minAxisX - offsetMinusX) * freqToPixelCooficient
        const sAxisWidth = (maxAxisX - minAxisX) * freqToPixelCooficient

        const setYOffset = this.graphSettings.chartHeight * 0.25

        const setBAckgroundRect = this.addRectangle(sminAxisX - 40, 0, sAxisWidth + 40, this.graphSettings.setHeight)
        const set1Background = this.addShape(setBAckgroundRect, setBackgroundColor, 0, (-this.graphSettings.setHeight / 2) + setYOffset, 0, 0, 0, 0, 1, "SET1", true)
        set1Background.material.transparent = true
        set1Background.material.opacity = 0.2

        const set2Background = this.addShape(setBAckgroundRect, setBackgroundColor, 0, (-this.graphSettings.setHeight / 2) - setYOffset, 0, 0, 0, 0, 1, "SET2", true)
        set2Background.material.transparent = true
        set2Background.material.opacity = 0.2

        const rectAxis = this.addRectangle(0, 0, (maxAxisX - minAxisX) * freqToPixelCooficient, 2)
        this.addShape(rectAxis, axisColor, (minAxisX - offsetMinusX) * freqToPixelCooficient, chartUpperBorder, 0, 0, 0, 0, 1, null, true)
        this.addShape(rectAxis, axisColor, (minAxisX - offsetMinusX) * freqToPixelCooficient, chartBottomBorder - 2, 0, 0, 0, 0, 1, null, true)

        const set01Name = this.addText((minAxisX - offsetMinusX) * freqToPixelCooficient - 20, (-this.graphSettings.setHeight / 2) + (setYOffset), font, currentSet.name, 1.57079633, 13)
        const set01Box = new THREE.Box3().setFromObject(set01Name)
        let labelHeight = set01Box.max.y - set01Box.min.y
        set01Name.position.y = ((-this.graphSettings.setHeight / 2) + setYOffset + (this.graphSettings.setHeight / 2)) - labelHeight / 2
        this.set1Group.position.y = setYOffset

        let vectorThicknes = this.graphSettings.setHeight / currentSet.vectors.length

        let zOffset = (currentSet.vectors.length * vectorThicknes) / 2

        for (let i = 0; i < currentSet.vectors.length; i++) {
          const currentVector = currentSet.vectors[i]
          const vectorName = this.addText((minAxisX - offsetMinusX) * freqToPixelCooficient, this.graphSettings.setHeight / 2 + 0.1, font, currentVector.fullName, 0, 10, this.set1Group)
          vectorName.position.z = (currentVector.level * vectorThicknes - zOffset) + (vectorThicknes / 2) + 5
          vectorName.rotation.x = -1.5708
        }

        //chart drawing
        for (let i = 0; i < currentSet.bands.length; i++) {
          const currentBand = currentSet.bands[i]
          const bandStart = currentBand.minFreq.log()
          const bandEnd = currentBand.maxFreq.log()
          const servicesCount = currentBand.services.length
          const heightForOneService = this.graphSettings.setHeight / servicesCount


          this.extrudeSettings.depth = vectorThicknes

          for (let j = 0; j < servicesCount; j++) {
            const currentServiceInfo = currentBand.services[j]
            const currentService = this.allServices[currentServiceInfo.idService] // gute service found
            if (currentService != null) {
              const rectColor = new THREE.Color(currentService.Color)
              const rectShape1 = this.addRectangle((bandStart - offsetMinusX) * freqToPixelCooficient, j * heightForOneService, (bandEnd - bandStart) * freqToPixelCooficient, heightForOneService)
              const userData = {
                "currentBand": currentBand,
                "currentServiceInfo": currentServiceInfo,
                "serviceInfo": currentService
              }
              const subVectorThicknes = vectorThicknes / currentServiceInfo.siblingsCount
              this.extrudeSettings.depth = subVectorThicknes
              this.addShape(rectShape1, rectColor, 0, -this.graphSettings.setHeight / 2, (currentServiceInfo.vectorLevel * vectorThicknes) + (currentServiceInfo.vectorSubLevel * subVectorThicknes) - zOffset, 0, 0, 0, 1, userData, false, this.set1Group)
            }
          }
        }

        currentSet = this.fatData.sets.find(function (element) {
          return element.name == "Secondary"
        })

        const set02Name = this.addText((minAxisX - offsetMinusX) * freqToPixelCooficient - 20, (-this.graphSettings.setHeight / 2) - (setYOffset), font, currentSet.name, 1.57079633, 13)
        const set02Box = new THREE.Box3().setFromObject(set02Name)
        labelHeight = set02Box.max.y - set02Box.min.y
        set02Name.position.y = ((-this.graphSettings.setHeight / 2) - setYOffset + (this.graphSettings.setHeight / 2)) - labelHeight / 2
        this.set2Group.position.y = -setYOffset

        vectorThicknes = this.graphSettings.setHeight / currentSet.vectors.length
        this.extrudeSettings.depth = vectorThicknes
        zOffset = (currentSet.vectors.length * vectorThicknes) / 2

        for (let i = 0; i < currentSet.vectors.length; i++) {
          const currentVector = currentSet.vectors[i]
          const vectorName = this.addText((minAxisX - offsetMinusX) * freqToPixelCooficient, this.graphSettings.setHeight / 2 + 0.1, font, currentVector.fullName, 0, 10, this.set2Group)
          vectorName.position.z = (currentVector.level * vectorThicknes - zOffset) + (vectorThicknes / 2) + 5
          vectorName.rotation.x = -1.5708
        }

        for (let i = 0; i < currentSet.bands.length; i++) {
          const currentBand = currentSet.bands[i]
          const bandStart = currentBand.minFreq.log()
          const bandEnd = currentBand.maxFreq.log()
          const servicesCount = currentBand.services.length
          const heightForOneService = this.graphSettings.setHeight / servicesCount

          for (let j = 0; j < servicesCount; j++) {
            const currentServiceInfo = currentBand.services[j]
            const currentService = this.allServices[currentServiceInfo.idService] // gute service found
            if (currentService != null) {
              const rectColor = new THREE.Color(currentService.Color)
              const rectShape1 = this.addRectangle((bandStart - offsetMinusX) * freqToPixelCooficient, j * heightForOneService, (bandEnd - bandStart) * freqToPixelCooficient, heightForOneService)
              const userData = {
                "currentBand": currentBand,
                "currentServiceInfo": currentServiceInfo,
                "serviceInfo": currentService
              }
              const subVectorThicknes = vectorThicknes / currentServiceInfo.siblingsCount
              this.extrudeSettings.depth = subVectorThicknes
              this.addShape(rectShape1, rectColor, 0, (-this.graphSettings.setHeight / 2), currentServiceInfo.vectorLevel * vectorThicknes + (currentServiceInfo.vectorSubLevel * subVectorThicknes) - zOffset, 0, 0, 0, 1, userData, false, this.set2Group)
            }
          }
        }

        if (this.renderer == null) {
          this.renderer = new THREE.WebGLRenderer({
            antialias: true
          })

          this.renderer.setClearColor(0xffffff, 1)
          this.renderer.setPixelRatio(window.devicePixelRatio)
          this.renderer.setSize(window.innerWidth * 0.98, window.innerHeight * 0.7)

          while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild)
          }

          this.container.appendChild(this.renderer.domElement)
        }

        if (this.container == null || !this.container.isConnected) {
          this.container = this.$refs.canvas

          while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild)
          }

          this.container.appendChild(this.renderer.domElement)
        }


        this.keepRendering = true
        this.animate()
        this.resetView()

        this.container.removeEventListener("mousemove", this.onDocumentMouseMove, false)
        this.container.addEventListener("mousemove", this.onDocumentMouseMove, false)
      }
    },
    animate() {
      this.cameraInfo.z += (this.cameraInfo.targetZ - this.cameraInfo.z) * 0.1
      this.infoBoxInfo.x = this.infoBoxInfo.targetX // - this.infoBoxInfo.x) * 1;
      this.infoBoxInfo.y = this.infoBoxInfo.targetY // - this.infoBoxInfo.y) * 1;
      this.infoBoxInfo.opacity += (this.infoBoxInfo.targetOpacity - this.infoBoxInfo.opacity) * 0.3
      if (this.keepRendering) {
        this.timeoutHandler = setTimeout(() => {
          this.animationFrameHandler = requestAnimationFrame(this.animate)
          if (!this.keepRendering) {
            cancelAnimationFrame(this.animationFrameHandler)
          }
        }, 1000 / 20)
        this.render()
      }
      this.camera.position.z = this.cameraInfo.z
      this.group.position.x += (this.targetXPosition - this.group.position.x) * 0.5

      this.set1Group.rotation.x += (this.cameraInfo.set1RotateTarget - this.set1Group.rotation.x) * 0.22
      this.set2Group.rotation.x += (this.cameraInfo.set2RotateTarget - this.set2Group.rotation.x) * 0.22

      //this.set1Group.rotation.x += 0.02;
    },
    render() {
      //if (this.container == null || !this.container.isConnected) {
      //        this.keepRendering = false;
      //        return;
      //    }
      const infoBox = this.$refs.infoBox
      if (infoBox != null) {
        infoBox.style.top = Math.round(this.infoBoxInfo.y) + "px"
        infoBox.style.left = Math.round(this.infoBoxInfo.x) + "px"
        //infoBox.style.opacity = this.infoBoxInfo.opacity;

        const boundingRect = infoBox.getBoundingClientRect()
        if ((boundingRect.x + boundingRect.width) > window.innerWidth) {
          this.infoBoxInfo.x -= boundingRect.width - 5
          infoBox.style.left = Math.round(this.infoBoxInfo.x) + "px"
        }
      }
      if (this.keepRendering) {
        this.renderer.render(this.scene, this.camera)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.infoBoxStyle {
  position: fixed;
  padding: 5px;
  border: 1px solid;
  z-index: 4;
}
.infoBoxStyleBottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 5px;
}
.hoverable {
  cursor: default;
  transition: all 0.1s ease-in-out;
  outline: none;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: none;
}
.hoverable:hover {
  box-shadow: 0 0 10px rgba(81, 203, 238, 1);
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  cursor: hand;
}
.chartLegend {
  background: rgb(246, 248, 249);
  background: -moz-linear-gradient(
    top,
    rgba(246, 248, 249, 1) 0%,
    rgba(229, 235, 238, 1) 50%,
    rgba(215, 222, 227, 1) 51%,
    rgba(245, 247, 249, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(246, 248, 249, 1) 0%,
    rgba(229, 235, 238, 1) 50%,
    rgba(215, 222, 227, 1) 51%,
    rgba(245, 247, 249, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(246, 248, 249, 1) 0%,
    rgba(229, 235, 238, 1) 50%,
    rgba(215, 222, 227, 1) 51%,
    rgba(245, 247, 249, 1) 100%
  );
}
</style>