import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import VueResource from "vue-resource"
import BootstrapVue from "bootstrap-vue"
import store from "./store"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faDownload,
  faTimes,
  faChevronDown,
  faChevronUp,
  faAsterisk,
  faTimesCircle,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import WebFont from "webfontloader"
import ToggleButton from "vue-js-toggle-button"

import * as api from "@/services/api.js"

WebFont.load({
  google: {
    families: ["Roboto:300,600"]
  }
})

library.add(faDownload)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faAsterisk)
library.add(faTimesCircle)
library.add(faAngleDoubleDown)
library.add(faAngleDoubleUp)
library.add(faTimes)
library.add(faInfoCircle)

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-balham.css"

Vue.config.productionTip = false

Vue.use(VueResource)
Vue.use(BootstrapVue)
Vue.use(ToggleButton)
Vue.component("FontAwesomeIcon", FontAwesomeIcon)

Number.prototype.log = function () {
  return Math.log(Math.max(this, 0.00001))
}

Number.prototype.format = function (n, x, s, c) {
  const re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
    num = this.toFixed(Math.max(0, ~~n))

  return (c ? num.replace(".", c) : num).replace(new RegExp(re, "g"), "$&" + (s || ","))
}

api.setup()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
