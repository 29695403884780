<template>
  <div>
    <div id="statsContainer">
      <table
        v-if="stats"
        class="table table-sm table-bordered"
      >
        <tbody>
          <tr>
            <td>Current ID</td>
            <td>{{ stats.DataSetIdentifier != null ? stats.DataSetIdentifier : '-' }}</td>
          </tr>
          <tr>
            <td>Next publication</td>
            <td>{{ stats.UpcomingPublish ? stats.UpcomingPublish.Dataset.Identifier : 'No upcoming publish' }}</td>
          </tr>
          <tr>
            <td>Switch time</td>
            <td>{{ stats.UpcomingPublish ? stats.UpcomingPublish.TargetDate : 'No upcoming publish' }}</td>
          </tr>
          <tr>
            <td>Current server time</td>
            <td>{{ serverTime }}</td>
          </tr>
          <tr>
            <td>Version</td>
            <td>{{ version }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import * as api from "@/services/api.js"

export default {
  data() {
    return {
      stats: null
    }
  },
  computed: {
    ...mapGetters(["version", "serverTime"])
  },
  async created() {
    const result = await api.getMoreStats()
    this.stats = result.data
  }

}
</script>

<style lang="scss" scoped>
#statsContainer {
  padding: 40px;

  table {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>