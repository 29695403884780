<template>
  <div id="app">
    <the-header />
    <keep-alive :include="keepAliveInclude">
      <router-view id="view" />
    </keep-alive>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import TheHeader from "@/components/TheHeader.vue"

import eventBus from "@/events.js"

import * as api from "@/services/api.js"

export default {
  components: {
    TheHeader
  },
  data() {
    return {
      isOffline: Boolean(Number(process.env.VUE_APP_OFFLINE)),
      maintenanceMode: false,
      keepAliveInclude: ["Fat", "Rirs"]
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn"])
  },
  watch: {
    async isLoggedIn() {
      //Couldn't find better way to reset it
      //:Key solution didn't work
      this.keepAliveInclude = []
      await this.$nextTick()
      this.keepAliveInclude = ["Fat", "Rirs"]
    }
  },
  async created() {
    if (this.isOffline) {
      return
    }
    eventBus.$on("maintenance-mode", this.onMaintenanceMode)
    eventBus.$on("token-expired", this.onTokenExpired)
    eventBus.$on("access-denied", this.onAccessDenied)
    const response = await api.getStats()
    this.setInfo(response.data)
  },
  beforeDestroy() {
    if (this.isOffline) {
      return
    }
    eventBus.$off("maintenance-mode", this.onMaintenanceMode)
    eventBus.$off("token-expired", this.onTokenExpired)
    eventBus.$off("access-denied", this.onAccessDenied)
  },
  methods: {
    ...mapActions(["setInfo", "clearCredentialsWithInfo"]),
    onMaintenanceMode() {
      if (this.$route.name !== "maintenance") {
        this.$router.push("maintenance")
      }
    },
    onTokenExpired() {
      this.clearCredentialsWithInfo("Token expired")
      this.$router.push("login")

    },
    onAccessDenied() {
      this.clearCredentialsWithInfo("You are not allowed to use this application")
      this.$router.push("login")
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  #view {
    top: 55px;
    position: relative;
  }

  .vue-js-switch {
    margin-bottom: 0;

    .v-switch-core,
    .v-switch-button {
      border-radius: 4px !important;
    }
    .v-switch-label {
      font-weight: normal;
      font-size: 16px;
    }
  }

  .loader-fixed {
    position: fixed;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    z-index: 3;
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
    &.small {
      width: 1rem;
      height: 1rem;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
</style>
