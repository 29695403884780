import Vue from "vue"
import Router from "vue-router"
import Rirs from "./views/Rirs.vue"
import Rir from "./views/Rir.vue"
import Docs from "./views/Docs.vue"
import Login from "./views/Login.vue"
import Fat from "./views/Fat.vue"
import Stats from "./views/Stats.vue"
import Maintenance from "./views/Maintenance.vue"

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/rirs/:type?",
      name: "rirs",
      component: Rirs
    },
    {
      path: "/rir/:id/:bodyId",
      name: "rir",
      component: Rir
    },
    {
      path: "/docs",
      name: "docs",
      component: Docs
    },
    {
      path: "/stats",
      name: "stats",
      component: Stats
    },
    {
      path: "/maintenance",
      name: "maintenance",
      component: Maintenance
    },
    {
      path: "/fatTable/(table|applications|allocations)?/:freqFrom?/:freqTo?",
      name: "fatTable",
      component: Fat
    },
    { path: "/", name: "default", component: Fat }
  ]
})

router.beforeEach((to, from, next) => {
  const isOffline = Boolean(Number(process.env.VUE_APP_OFFLINE))
  if (isOffline && to.name !== "rirs" && to.name !== "rir") {
    next({ name: "rirs" })
  }
  else {
    next()
  }
})

export default router