<template>
  <!-- eslint-disable vue/no-v-html -->
  <tr
    v-show="visible"
    :key="header.id"
    :class="{
      'filtered': header.filtered,
      'header-group-even': sortBy === 'name' && header.groupColorEven,
      'header-group-odd': sortBy === 'name' && !header.groupColorEven,
      'header-group-even': sortBy !== 'name'
    }"
  >
    <td>
      <div class="d-flex">
        <span class="caretContainer pr-1" @click="$emit('toggle-header')">
          <font-awesome-icon v-if="!header.expanded" icon="chevron-down" />
          <font-awesome-icon v-else icon="chevron-up" />
        </span>
        <span class="caretContainer pr-1" @click="$emit('toggle-header-deep')">
          <div v-if="!header.expanded || !hasAllBodiesExpanded" class="double-chevron-container">
            <font-awesome-icon icon="chevron-down" />
            <font-awesome-icon icon="chevron-down" />
          </div>
          <div v-else class="double-chevron-container">
            <font-awesome-icon icon="chevron-up" />
            <font-awesome-icon icon="chevron-up" />
          </div>
        </span>
        <b-form-checkbox
          :checked="header.selected"
          :indeterminate="hasAnyBodySelected && !hasAllBodiesSelected"
          inline
          class="mr-0"
          @change="$emit('header-selected')"
        />
        <b-btn
          v-if="!isDraftsGroupHeader"
          variant="link"
          :href="`/api/RIR/${header.rirId}`"
          target="_blank"
          class="text-left p-0 m-0"
        >
          {{ displayName }}
        </b-btn>
        <span v-else>{{ displayName }}</span>
      </div>
    </td>
    <td>{{ freqDisplay }}</td>
    <td />
    <td>{{ !isDraftsGroupHeader ? header.version : "" }}</td>
    <td>{{ !isDraftsGroupHeader ? dateDisplay : "" }}</td>
  </tr>
</template>

<script>
import * as texts from "@/services/texts.js"
import * as rirs from "@/services/rirs.js"

export default {
  props: {
    header: {
      type: Object,
      required: true
    },
    sortBy: {
      type: String,
      default: null
    },
    mode: {
      type: Boolean,
      default: false
    },
    selectionMode: {
      type: String,
      required: true
    }
  },
  computed: {
    isDraftsGroupHeader() {
      return this.header.sort === "Draft"
    },
    visible() {
      return rirs.isHeaderVisible(this.header, this.selectionMode, this.mode)
    },
    hasAnyBodySelected() {
      return this.header.bodies.filter(b => this.isBodyVisible(b)).some(b => b.selected)
    },
    hasAllBodiesSelected() {
      return this.header.bodies.filter(b => this.isBodyVisible(b)).every(b => b.anyPositionSelected || b.selected)
    },
    hasAllBodiesExpanded() {
      return this.header.bodies.filter(b => this.isBodyVisible(b)).every((b) => b.expanded)
    },
    displayName() {
      return texts.getHeaderDisplayName(this.header)
    },
    dateDisplay() {
      if (this.header.date) {
        const timeStamp = this.header.date.substring(6, 19)
        const d = new Date(timeStamp * 1)
        return texts.formatDate(d)
      }
      return null
    },
    freqMin() {
      return texts.getHeaderMinFreq(this.header)
    },
    freqMax() {
      return texts.getHeaderMaxFreq(this.header)
    },
    freqMinFormatted() {
      return texts.formatFrequency(this.freqMin)
    },
    freqMaxFormatted() {
      return texts.formatFrequency(this.freqMax)
    },
    freqDisplay() {
      return `${this.freqMinFormatted} - ${this.freqMaxFormatted}`
    }
  },
  methods: {
    isBodyVisible(body) {
      return rirs.isBodyVisible(body, this.selectionMode, this.mode)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-group-even {
  background-color: #fafafa !important;
}

.header-group-odd {
  background-color: #eaeaea !important;
}
</style>