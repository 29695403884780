<template>
  <div class="info-container pt-1" :class="{'shown': info !== null }">
    <b-button variant="link" class="float-right p-1 mr-1" size="lg"
              @click="$emit('close')"
    >
      <font-awesome-icon icon="times-circle" />
    </b-button>
    <b-row v-if="info">
      <b-col cols="3">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <td class="font-weight-bold">Application</td>
              <td>{{ info.data.application }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Frequency</td>
              <td>{{ frequencyFormatted }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Layers</td>
              <td>
                <div>{{ info.data.applicationLevel1 }}</div>
                <div>{{ info.data.applicationLevel2 }}</div>
                <div>{{ info.data.applicationLevel3 }}</div>
              </td>
            </tr>
            <tr>
              <td class="font-weight-bold">Footnotes</td>
              <td>
                <div v-for="(note, index) in info.data.notesContext" :key="index">
                  <a target="_blank" :href="note.url">{{ note.urlText }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col cols="9">
        <div>
          <span class="font-weight-bold">Radio Interface Regulations</span>
          <b-button variant="link" class="p-0" @click="tableExpanded = !tableExpanded">
            <font-awesome-icon v-if="tableExpanded" icon="chevron-up" />
            <font-awesome-icon v-else icon="chevron-down" />
          </b-button>
        </div>
        <div class="rir-table-container">
          <table v-if="tableExpanded" class="table table-bordered table-sm rir-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
              </tr>
            </thead>
            <fat-chart-applications-info-rir-item
              v-for="(rirContext, index) in info.data.rirsContext"
              :key="index"
              :index="index"
              :application="info.data.application"
              :item="rirContext"
            />
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FatChartApplicationsInfoRirItem from "@/components/FatChartApplicationsInfoRirItem.vue"
import * as texts from "@/services/texts.js"

export default {
  components: {
    FatChartApplicationsInfoRirItem
  },
  props: {
    info: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      tableExpanded: true
    }
  },
  computed: {
    frequencyFormatted() {
      return `${texts.formatFrequency(this.info.data.lowerFrequency)} - ${texts.formatFrequency(this.info.data.upperFrequency)}`
    }
  }
}
</script>

<style lang="scss" scoped>
.info-container {
  position: fixed;
  bottom: -300px;
  left: 0;
  width: 100%;
  height: 300px;
  background: white;
  transition-property: all;
  transition-duration: 0.5s;

  &.shown {
    bottom: 0;
    box-shadow: 0px -4px 18px -3px rgba(0, 0, 0, 0.75);
  }
}

.rir-table-container {
  height: 260px;
  overflow-y: auto;
  overflow-x: hidden;

  ::v-deep .rir-table {
    tbody {
      tr {
        background-color: #f0f0f0;

        &.info-row {
          background-color: white;
        }
      }
    }
  }
}
</style>
