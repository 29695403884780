<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="container-fluid">
    <b-collapse id="rirsFiltersContainer" visible class="container-fluid py-2">
      <div id="rirsContainer">
        <b-row class="mt-2">
          <b-col cols="3">
            <div>Search string</div>
            <input v-model="filters.searchString" type="text" class="form-control">
          </b-col>
          <b-col cols="3">
            <b-row>
              <b-col>
                <div>Freq from (MHz)</div>
                <input v-model="filters.lowerFreq" type="text" class="form-control">
              </b-col>
              <b-col>
                <div>Freq to (MHz)</div>
                <input v-model="filters.upperFreq" type="text" class="form-control">
              </b-col>
            </b-row>
          </b-col>

          <b-col v-if="isLoggedIn" cols="2">
            <div>State</div>
            <select v-model="filters.state" class="form-control">
              <option value="1">All</option>
              <option value="0">Active</option>
              <option value="2">Obsolete</option>
              <option value="3">Pre-draft</option>
            </select>
          </b-col>
          <b-col class="d-flex buttons-container pt-4">
            <b-btn variant="success" :disabled="loading" @click="onSearchButtonClick">
              <span>Search</span>
            </b-btn>
            <b-btn class="mx-1" variant="primary" @click="clearFilters">Clear filters</b-btn>
            <toggle-button
              v-model="mode"
              sync
              :height="40"
              :width="145"
              :labels="{checked: 'General view', unchecked: 'Filtered view'}"
              :color="{unchecked: '#007BFF', checked: '#007BFF'}"
              class="mr-1"
            />
            <b-btn
              v-if="isHelpPdfPresent"
              href="/assets/rirs-help.pdf"
              variant="primary"
              target="_blank"
            >
              <span class="mr-1">Help</span>
              <font-awesome-icon icon="info-circle" />
            </b-btn>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="3" class="d-flex">
            <b-radio-group v-model="selectionMode" :disabled="loading" size="sm">
              <b-form-radio name="mode" value="all">All</b-form-radio>
              <b-form-radio name="mode" value="selected">Selected</b-form-radio>
            </b-radio-group>
            <b-btn
              v-if="availablePositions.length"
              variant="primary"
              size="sm"
              @click="$refs.modalPositions.show()"
            >
              Select positions
            </b-btn>
          </b-col>
          <b-col cols="3">
            <span class="mr-2">Filtering methods:</span>
            <b-btn id="filtersButton" variant="primary" size="sm"
                   @click="$refs.modal.show()"
            >
              <span>{{ freqFiltersDisplay }}</span>
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-collapse>
    <div v-if="loading" class="d-flex loader-fixed justify-content-center mb-3">
      <b-spinner label="Loading..." variant="primary" />
    </div>

    <rirs-table
      :rirs-headers="rirsHeaders"
      :sort-by="sortBy"
      :sort-asc="sortAsc"
      :mode="mode"
      :selection-mode="selectionMode"
      :search-string="filters.searchString"
      :class="{'extra-margin': filtersExpanded}"
      @sort="sort($event)"
      @toggle-header="toggleHeader"
      @toggle-header-deep="toggleHeaderDeep"
      @header-selected="selectHeader"
      @toggle-body="toggleBody"
      @body-selected="selectBody"
      @position-selected="selectPosition"
      @position-selected-all="selectAllPositions"
      @toggle-all-to-bodies="toggleAll"
      @toggle-all-to-positions="toggleAll(true)"
    />

    <b-modal ref="modal" hide-footer title="Filtering methods">
      <b-row>
        <b-col>
          <b-form-radio-group v-model="filters.filteringMode" :options="options" stacked />
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="filters.draftsOnly"
          >
            Drafts only
          </b-form-checkbox>
        </b-col>
      </b-row>
      <div class="modal-footer pb-0 pt-1">
        <b-btn size="sm" variant="primary" class="pull-left"
               @click="$refs.modal.hide()"
        >
          <span>Cancel</span>
        </b-btn>
        <b-btn size="sm" variant="success" class="pull-right"
               @click="onModalClose()"
        >
          <span>Ok</span>
        </b-btn>
      </div>
    </b-modal>

    <b-modal
      ref="modalPositions"
      hide-footer
      title="Select positions"
      content-class="select-positions-modal"
    >
      <div class="select-positions-info">
        Select positions in visible RIR bodies.
        <br>'Selected' shows only RIR with selected positions and ‘marked’ RIR’s and RIR-groups.
      </div>
      <table class="table table-bordered table-sm details-table mt-2">
        <thead>
          <tr class="background-gray">
            <th>
              <b-form-checkbox
                :indeterminate="anyAvailablePositionsSelected && !allAvailablePositionsSelected"
                :checked="allAvailablePositionsSelected"
                @change="updateAvailablePositions"
              >
                Nr
              </b-form-checkbox>
            </th>
            <th>Parameter</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(position, index) in availablePositions" :key="index">
            <td>
              <b-form-checkbox v-model="position.selected" inline class="mr-0">{{ index + 1 }}</b-form-checkbox>
            </td>
            <td>{{ position.positionName }}</td>
          </tr>
        </tbody>
      </table>
      <div class="modal-footer pb-0 pt-1">
        <b-btn
          size="sm"
          variant="primary"
          class="btn pull-left"
          @click="$refs.modalPositions.hide()"
        >
          <span>Cancel</span>
        </b-btn>
        <b-btn size="sm" variant="success" class="btn pull-right"
               @click="onPositionsModalClose()"
        >
          <span>Ok</span>
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import * as api from "@/services/api.js"
import * as texts from "@/services/texts.js"
import * as rirs from "@/services/rirs.js"

import store from "@/store"

import RirsTable from "@/components/RirsTable.vue"

export default {
  name: "Rirs",
  components: {
    RirsTable
  },
  data() {
    return {
      filters: {
        state: store.getters["isLoggedIn"] ? 0 : 1,
        searchString: null,
        filteringMode: 0,
        lowerFreq: null,
        upperFreq: null,
        draftsOnly: false
      },
      initialFilters: null,
      toggledBodies: false,
      rirsHeaders: [],
      sortAsc: false,
      sortBy: "name",
      loading: false,
      options: [
        { text: "All", value: null },
        { text: "Inside and before", value: 1 },
        { text: "Inside only", value: 0 },
        { text: "Inside and after", value: 2 }
      ],
      mode: true,
      selectionMode: "all",
      availablePositions: [],
      filtersExpanded: true,
      isHelpPdfPresent: false
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "defaultPositions"]),
    freqFiltersDisplay() {
      let ret = this.options.find(o => o.value === this.filters.filteringMode).text
      if (this.filters.draftsOnly) {
        ret += " | Drafts"
      }

      return ret
    },
    filtersOutput() {
      const output = { ...this.filters }
      if (output.lowerFreq) {
        output.lowerFreq = output.lowerFreq * 1000000
      } else {
        output.lowerFreq = null
      }

      if (output.upperFreq) {
        output.upperFreq = output.upperFreq * 1000000
      } else {
        output.upperFreq = null
      }

      output.hideEmptyHeders = true
      output.filteringMode = output.filteringMode === null ? [0, 1, 2] : [output.filteringMode]
      return output
    },
    anyAvailablePositionsSelected() {
      return this.availablePositions.filter(p => p.selected).length > 0
    },
    allAvailablePositionsSelected() {
      return this.availablePositions.filter(p => p.selected).length === this.availablePositions.length
    },
    filtersChanged() {
      return JSON.stringify(this.filters) !== JSON.stringify(this.initialFilters)
    }
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal.name === "rirs" && oldVal.name === "rirs") {
        this.search()
      }
    }
  },
  created() {
    this.initialFilters = { ...this.filters }
    this.filters.draftsOnly = Boolean(this.$route.params.type)
    this.checkPdfFilePresence()
    this.search()

    this.$root.$on("bv::toggle::collapse", (selector) => {
      if (selector === "rirsFiltersContainer") {
        this.filtersExpanded = !this.filtersExpanded
      }
    })
  },
  methods: {
    async search() {
      this.loading = true

      try {
        const result = await api.getRirs(this.filtersOutput)
        this.formatRirs(result.data.Headers)
        this.rirsHeaders = result.data.Headers
        this.sortAsc = false
        this.sort("name")
        this.setAvailablePositions()

      } finally {
        this.loading = false
      }
    },
    onSearchButtonClick() {
      if (this.filtersChanged) {
        this.mode = false
      }

      this.search()
    },
    formatRirs(rirs) {
      rirs.forEach((rirHeader) => this.formatRirHeader(rirHeader))
    },
    clearFilters() {
      this.filters = { ...this.initialFilters }
      this.mode = true
      this.search()
    },
    updateFreqFilters(value) {
      const index = this.filters.filteringMode.indexOf(value)
      if (index != -1) {
        this.filters.filteringMode.splice(index, 1)
      }
      else {
        this.filters.filteringMode.push(value)
      }
    },
    sort(filterName) {
      this.sortBy = filterName
      this.sortAsc = !this.sortAsc
      this.sortHeaders(this.sortBy, this.sortAsc)

      this.rirsHeaders.forEach((header) => {
        this.sortBodies(header, this.sortBy, this.sortAsc)
      })

      this.setGroupColors()
    },
    setGroupColors() {
      for (let i = 0; i < this.rirsHeaders.length; i++) {
        const header = this.rirsHeaders[i]
        const headerPrev = this.rirsHeaders[i - 1]

        if (!headerPrev) {
          header.groupColorEven = true
        }
        else if (header.group === headerPrev.group) {
          header.groupColorEven = headerPrev.groupColorEven
        } else {
          header.groupColorEven = !headerPrev.groupColorEven
        }
      }
    },
    sortBodies(header, sortBy, sortAsc) {
      header.bodies.sort((a, b) => {
        const modifier = sortAsc ? 1 : -1

        switch (sortBy) {
          case "freq": {
            const aMinFreq = texts.getBodyMinFreq(a)
            const bMinFreq = texts.getBodyMinFreq(a)
            return modifier * (aMinFreq - bMinFreq)
          }

          default: {
            const aDisplayName = texts.getBodyDisplayName(header.rirName, a)
            const bDisplayName = texts.getBodyDisplayName(header.rirName, b)

            if (aDisplayName === bDisplayName) {
              return modifier * (a.version || "").localeCompare(b.version)
            }

            return modifier * aDisplayName.localeCompare(bDisplayName)
          }
        }
      })
    },
    sortHeaders(sortBy, sortAsc) {
      this.rirsHeaders.sort((a, b) => {
        const modifier = sortAsc ? 1 : -1

        switch (sortBy) {
          case "freq": {
            const aMinFreq = texts.getHeaderMinFreq(a)
            const bMinFreq = texts.getHeaderMinFreq(b)
            return modifier * (aMinFreq - bMinFreq)
          }

          default: {
            const aDisplayName = texts.getHeaderDisplayName(a)
            const bDisplayName = texts.getHeaderDisplayName(b)
            return modifier * aDisplayName.localeCompare(bDisplayName)
          }
        }
      })
    },
    formatRirHeader(rirHeader) {
      rirHeader.bodies.forEach((rirBody) => {
        rirBody.expanded = false
        rirBody.selected = false
        rirBody.anyPositionSelected = false

        rirBody.positions.forEach((position) => {
          position.selected = false
        })

        rirBody.positions.sort((a, b) => a.positionNumber - b.positionNumber)
      })

      rirHeader.selected = false
      rirHeader.expanded = this.filtersChanged && rirHeader.filtered
      rirHeader.group = rirHeader.rirName.substring(3, 5)
      rirHeader.groupColorEven = null
    },
    toggleAll(deep) {
      const visibleHeaders = this.rirsHeaders.filter(h => this.isHeaderVisible(h))
      const allHeadersExpanded = visibleHeaders.every(h => h.expanded)
      const allBodiesExpanded = visibleHeaders.every(h => h.bodies.filter(b => this.isBodyVisible(b)).every(b => b.expanded))
      this.rirsHeaders.forEach((rirHeader) => {
        if (deep) {
          if (allBodiesExpanded) {
            this.collapseHeader(rirHeader)
          }
          else {
            if (this.isHeaderVisible(rirHeader)) {
              rirHeader.expanded = true
            }
            rirHeader.bodies.forEach((body) => {
              if (this.isBodyVisible(body)) {
                body.expanded = true
              }
            })
          }
        }
        else {
          if (allHeadersExpanded) {
            this.collapseHeader(rirHeader)
          }
          else if (this.isHeaderVisible(rirHeader)) {
            rirHeader.expanded = true
          }
        }
      })
    },
    collapseHeader(header) {
      header.expanded = false
      header.bodies.forEach((body) => body.expanded = false)
    },
    toggleHeaderDeep(rirHeader) {
      this.toggleHeader(rirHeader, true)
    },
    toggleHeader(rirHeader, deep) {
      const wasExpanded = rirHeader.expanded
      const hasAllBodiesExpanded = rirHeader.bodies.filter(b => this.isBodyVisible(b)).every(b => b.expanded)
      const hasAnyBodyExpanded = rirHeader.bodies.some(b => b.expanded && this.isBodyVisible(b))
      let expandBodies = false

      if (wasExpanded) {
        if (deep) {
          if (hasAllBodiesExpanded) {
            rirHeader.expanded = false
          } else {
            expandBodies = true
          }
        }
        else {
          rirHeader.expanded = hasAnyBodyExpanded
        }
      } else {
        rirHeader.expanded = true
        if (deep) {
          expandBodies = true
        }
      }

      rirHeader.bodies.forEach((b) => b.expanded = expandBodies && this.isBodyVisible(b))
    },
    toggleBody({ header, index }) {
      header.bodies[index].expanded = !header.bodies[index].expanded
    },
    selectHeader(header) {
      header.selected = !header.selected
      header.bodies.filter(b => this.isBodyVisible(b)).forEach((body) => {
        body.selected = header.selected
      })
      this.updateSelections()
    },
    selectBody({ header, index }) {
      header.bodies[index].selected = !header.bodies[index].selected
      this.updateSelections()
    },
    selectPosition({ header, index, positionIndex }) {
      header.bodies[index].positions[positionIndex].selected = !header.bodies[index].positions[positionIndex].selected
      this.updateSelections()
    },
    selectAllPositions({ header, index }) {
      const body = header.bodies[index]
      const allPositionSelected = body.positions.every(p => p.selected)
      const anyPositionSelected = body.positions.some(p => p.selected)

      body.positions.forEach(p => {
        if (!anyPositionSelected) {
          p.selected = this.defaultPositions.includes(Number(p.positionNumber))
        }
        else {
          p.selected = !allPositionSelected
        }
      })
      this.updateSelections()
    },
    updateSelections() {
      this.rirsHeaders.forEach((header) => {
        header.bodies.forEach((body) => {
          body.anyPositionSelected = body.positions.some(p => p.selected)
        })
      })
    },
    onModalClose() {
      this.$refs.modal.hide()

      this.$router.replace({
        name: "rirs",
        params: {
          type: this.filters.draftsOnly ? "drafts" : undefined
        }
      })

      this.search()
    },
    setAvailablePositions() {
      if (this.availablePositions.length) {
        return
      }

      if (!this.rirsHeaders.length) {
        this.availablePositions = []
        return
      }

      this.rirsHeaders[0].bodies[0].positions.forEach(p => {
        this.availablePositions.push({
          ...p,
          selected: this.defaultPositions.includes(Number(p.positionNumber))
        })
      })

    },
    updateAvailablePositions() {
      const allSelected = this.allAvailablePositionsSelected
      this.availablePositions.forEach(p => p.selected = !allSelected)
    },
    onPositionsModalClose() {
      const selectedPositionNames = this.availablePositions.filter(p => p.selected).map((p) => p.positionName)
      this.rirsHeaders.forEach((header) => {
        header.bodies.forEach((body) => {
          if (!this.isBodyVisible(body)) {
            return
          }
          body.positions.forEach((position) => {
            position.selected = selectedPositionNames.includes(position.positionName)
          })
        })
      })
      this.updateSelections()
      this.$refs.modalPositions.hide()
    },
    isBodyVisible(body) {
      return rirs.isBodyVisible(body, this.selectionMode, this.mode)
    },
    isHeaderVisible(header) {
      return rirs.isHeaderVisible(header, this.selectionMode, this.mode)
    },
    async checkPdfFilePresence() {
      try {
        await api.checkHelpFilePresence()
        this.isHelpPdfPresent = true
      }
      catch (err) {
        this.isHelpPdfPresent = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container {
  button {
    max-height: 40px;
  }
}

#rirsFiltersContainer {
  position: fixed;
  top: 56px;
  background-color: white;
  width: 100%;
  z-index: 2;
}

.rirs-table {
  margin-top: 16px;
  transition: margin 0.5s;
  &.extra-margin {
    margin-top: 126px;
  }
}

.select-positions-modal {
  .select-positions-info {
    font-size: 0.8rem;
    font-style: italic;
  }

  table {
    margin-bottom: 0;
    font-size: 0.9rem;

    td {
      padding: 0.2rem;
    }
  }
}
</style>

<style lang="scss">
.select-positions-modal {
  .modal-body {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
</style>