<template>
  <div style="max-width:600px; margin-left:auto; margin-right:auto;">
    <div style="padding: 30px; text-align: center; height: 180px;">
      <img v-if="useCustomLogos" src="/LoginPageLogo.png">
    </div>
    <div v-if="additionalLogoutInfo" class="alert alert-warning">{{ additionalLogoutInfo }}</div>
    <div v-if="error" class="alert alert-danger">{{ error }}</div>
    <validation-observer v-slot="{ invalid }">
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="username">Username</label>
          <i class="fa fa-key" />
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              id="username"
              v-model="username"
              type="text"
              name="username"
              class="form-control"
            >
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <i class="fa fa-lock" />
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              id="password"
              v-model="password"
              type="password"
              name="password"
              class="form-control"
            >
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <div style="text-align: center" class="form-actions">
          <button type="submit" :disabled="invalid" class="btn btn-success btn-block btn-lg">Login</button>
          <div v-if="logging" class="d-flex justify-content-center mt-3">
            <b-spinner label="Loading..." variant="primary" />
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import { ValidationProvider, ValidationObserver, extend } from "vee-validate"
import { required } from "vee-validate/dist/rules"

extend("required", required)

import * as api from "@/services/api.js"

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      error: null,
      logging: false,
      username: "",
      password: ""
    }
  },
  computed: {
    ...mapGetters(["additionalLogoutInfo", "useCustomLogos"])
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    async login() {
      this.logging = true
      this.error = null
      try {
        const response = await api.login(this.username, this.password)
        if (response.isOk) {
          if (response.resultCode == "LOGIN_OK") {
            this.setUserInfo(response.data)
            this.$router.push("fatTable")
          } else if (response.resultCode == "LOGIN_BAD_USER_OR_PASSWORD") {
            this.error = response.resultCode
          } else {
            this.error = "Is not OK"
          }
        } else {
          this.error = "Server error"
        }
      } finally {
        this.logging = false
      }
    }
  }
}
</script>
