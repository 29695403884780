import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

if (!window.config) {
  window.config = {
    defaultPositions: []
  }
  console.error("Config.js file not found")
}

export default new Vuex.Store({
  state: {
    intranet: window.config.intranet,
    defaultPositions: window.config.defaultPositions,
    topBar: {
      message: {
        text: window.config.topBarMessage,
        color: window.config.topBarMessageColor,
        backgroundColor: window.config.topBarMessageBackgroundColor,
        fontSize: window.config.topBarMessageFontSize,
        fontWeight: window.config.topBarMessageFontWeight,
      },
      logoUrl: window.config.topBarLogoUrl,
    },
    useCustomLogos: window.config.useCustomLogos,
    additionalLogoutInfo: null,
    infoUser: {
      currentToken: {
        name: "PUBLIC",
        userName: null
      }
    },
    info: {
      version: null,
      serverTime: null
    },
    chartVisible: true,
    persistedFilters: {
      idPreset: null,
      ituServices: [],
      vectorDef: [],
      applications: [],
      lowerFreq: 0,
      upperFreq: 1,
      selectedLowerFreq: 1000000,
      selectedUpperFreq: 1000000,
      searchString: "",
      selectedRestrictions: ""
    }
  },
  getters: {
    info(state) {
      return state.info
    },
    intranet(state) {
      return state.intranet
    },
    topBar(state) {
      return state.topBar
    },
    additionalLogoutInfo(state) {
      return state.additionalLogoutInfo
    },
    isChartVisible(state) {
      return state.chartVisible
    },
    isLoggedIn(state) {
      return Boolean(state.infoUser.currentToken.userName)
    },
    userName(state) {
      return state.infoUser.currentToken.userName
    },
    token(state) {
      return state.infoUser.currentToken.name
    },
    version(state) {
      return state.info.version
    },
    serverTime(state) {
      return state.info.serverTime
    },
    persistedFilters(state) {
      return state.persistedFilters
    },
    selectedVectorsIds(state) {
      return state.selectedVectorsIds
    },
    selectedServicesIds(state) {
      return state.selectedServicesIds
    },
    defaultPositions(state) {
      return state.defaultPositions
    },
    useCustomLogos(state) {
      return state.useCustomLogos
    }
  },
  mutations: {
    SET_ADDITIONAL_LOGOUT_INFO: (state, info) => {
      state.additionalLogoutInfo = info
    },
    SET_USER_INFO: (state, data) => {
      if (data) {
        state.infoUser = data
        return
      }
      state.infoUser = {
        currentToken: {
          name: "PUBLIC",
          userName: null
        }
      }
    },
    SET_INFO: (state, data) => {
      state.info = { ...state.info, ...data }
    },
    SET_PERSISTED_FILTERS: (state, filters) => {
      state.persistedFilters = { ...filters }
    }
  },
  actions: {
    clearCredentialsWithInfo: (context, info) => {
      context.commit("SET_ADDITIONAL_LOGOUT_INFO", info)
      context.commit("SET_USER_INFO")
    },
    setUserInfo: (context, data) => {
      context.commit("SET_USER_INFO", data)
    },
    setInfo: (context, stats) => {
      context.commit("SET_INFO", stats)
    },
    setPersistedFilters: (context, filters) => {
      context.commit("SET_PERSISTED_FILTERS", filters)
    }
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ["infoUser"]
    })
  ]
})
