<template>
  <b-collapse
    id="filtersContainer"
    visible
    class="container-fluid pt-2"
    @shown="$emit('height-change')"
    @hidden="$emit('height-change')"
  >
    <validation-observer v-slot="{ invalid }">
      <b-row no-gutters>
        <b-col cols="9">
          <b-row>
            <b-col>
              <div class="input-group">
                <div class="input-group-prepend">
                  <b class="input-group-text">Presets:</b>
                </div>
                <b-form-select v-model="filters.idPreset" @change="changePresets(false)">
                  <option :value="null" selected="selected">-- choose preset --</option>
                  <option
                    v-for="service in optionPresets"
                    :key="service.id"
                    :value="service.id"
                  >
                    {{ service.name }}
                  </option>
                </b-form-select>
              </div>
            </b-col>
            <b-col>
              <div class="input-group">
                <div class="input-group-prepend">
                  <b class="input-group-text">Lower freq:</b>
                </div>
                <validation-provider rules="required|min_value:0" class="validation-container">
                  <input
                    v-model="filters.lowerFreq"
                    type="text"
                    class="form-control"
                    @input="resetSelectedBands"
                  >
                </validation-provider>
                <div class="input-group-append">
                  <b-form-select
                    :value="filters.selectedLowerFreq"
                    @change="lowerFreqUnitChange($event)"
                  >
                    <option
                      v-for="metric in metrices"
                      :key="metric.metric"
                      :value="metric.metric"
                    >
                      {{ metric.name }}
                    </option>
                  </b-form-select>
                </div>
              </div>
            </b-col>
            <b-button variant="link" class="p-0 m-0" size="xs"
                      @click="toggleFreqSlider"
            >
              <font-awesome-icon v-if="!freqSliderExpanded" icon="angle-double-down" />
              <font-awesome-icon v-else icon="angle-double-up" />
            </b-button>
            <b-col>
              <div class="input-group">
                <div class="input-group-prepend">
                  <b class="input-group-text">Upper freq:</b>
                </div>
                <validation-provider rules="required|min_value:0" class="validation-container">
                  <input
                    v-model="filters.upperFreq"
                    type="text"
                    class="form-control"
                    @input="resetSelectedBands"
                  >
                </validation-provider>
                <div class="input-group-append">
                  <b-form-select
                    :value="filters.selectedUpperFreq"
                    @change="upperFreqUnitChange($event)"
                  >
                    <option
                      v-for="metric in metrices"
                      :key="metric.metric"
                      :value="metric.metric"
                    >
                      {{ metric.name }}
                    </option>
                  </b-form-select>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-show="freqSliderExpanded">
            <div class="slider-container">
              <div class="d-flex justify-content-around">
                <b-btn
                  v-for="band in bands"
                  :key="band.Abbreviation"
                  :variant="band === selectedLowerBand || band === selectedUpperBand ? 'primary' : 'outline-primary'"
                  class="p-0 m-0 px-1"
                  size="sm"
                  @click="selectBand(band)"
                >
                  {{ band.Abbreviation }}
                </b-btn>
              </div>
              <div ref="slider" class="slider" />
            </div>
          </b-row>
          <b-row>
            <b-col>
              <div class="input-group">
                <div class="input-group-prepend">
                  <b class="input-group-text">Text:</b>
                </div>
                <input
                  v-model="filters.searchString"
                  type="text"
                  class="form-control"
                  placeholder="Search string"
                >
              </div>
            </b-col>
            <b-col class="modal-buttons-col">
              <div class="d-flex justify-space-between">
                <b-button
                  type="button"
                  variant="primary"
                  class="px-1 flex-fill"
                  @click="$refs.servicesModal.show()"
                >
                  Services
                  <font-awesome-icon v-if="anyServicesSelected" icon="asterisk" size="xs" />
                </b-button>
                <b-button
                  type="button"
                  variant="primary"
                  class="px-1 mx-1 flex-fill"
                  @click="$refs.vectorsModal.show()"
                >
                  Vectors
                  <font-awesome-icon v-if="anyVectorSelected" icon="asterisk" size="xs" />
                </b-button>
                <b-button
                  v-if="mode ==='applications'"
                  type="button"
                  variant="primary"
                  class="px-1 flex-fill"
                  @click="$refs.applicationsModal.show()"
                >
                  RS
                  <font-awesome-icon v-if="anyApplicationSelected" icon="asterisk" size="xs" />
                </b-button>
              </div>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="pr-0">
                  <b-radio-group
                    v-model="mode"
                    :disabled="loading"
                    stacked
                    size="sm"
                    @change="onModeChange"
                  >
                    <b-form-radio name="mode" value="table">Table</b-form-radio>
                    <b-form-radio name="mode" value="allocations">Allocations</b-form-radio>
                    <b-form-radio name="mode" value="applications">Applications</b-form-radio>
                  </b-radio-group>
                </b-col>
                <b-col class="pl-0">
                  <div v-if="isLoggedIn" class="input-group">
                    <div class="input-group-prepend">
                      <b class="input-group-text">Restrictions:</b>
                    </div>
                    <b-form-select v-model="filters.selectedRestrictions" class="form-control">
                      <option value selected="selected">ALL</option>
                      <option value="SUI">SUI</option>
                      <option value="LIE">LIE</option>
                    </b-form-select>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row no-gutters>
            <b-col class="pr-2">
              <b-button
                :disabled="loadingOffline"
                type="button"
                block
                variant="primary"
                @click="getFatTableOffline"
              >
                <div v-if="loadingOffline" class="d-flex justify-content-center my-1">
                  <b-spinner label="Loading..." class="small" />
                </div>
                <font-awesome-icon v-else icon="download" />
              </b-button>
            </b-col>
            <b-col>
              <b-button
                v-if="mode === 'table'"
                type="button"
                block
                variant="primary"
                class="px-0"
                @click="$emit('reset-columns')"
              >
                Reset columns
              </b-button>
              <b-button
                v-if="mode === 'applications'"
                type="button"
                block
                variant="primary"
                @click="$emit('reset-zoom')"
              >
                Reset zoom
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                id="searchButton"
                :disabled="invalid || loading"
                type="submit"
                block
                variant="success"
                @click="search"
              >
                Search
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </validation-observer>
    <b-modal ref="vectorsModal" scrollable size="lg">
      <template #modal-header>
        <div class="d-flex align-items-center justify-content-between w-100">
          <div>
            <h5>Vectors</h5>
          </div>
          <div class="d-flex flex-column align-items-end">
            <b-btn variant="link" class="m-0 p-0" @click="$refs.vectorsModal.hide()">
              <font-awesome-icon icon="times" />
            </b-btn>
          </div>
        </div>
      </template>
      <b-row>
        <b-col v-for="vectorGroup in optionVectors" :key="vectorGroup.id">
          <div class="panel panel-default">
            <div class="panel-heading">
              <b-form-checkbox
                v-model="vectorGroup.allSelected"
                :indeterminate="vectorGroup.anySelected && !vectorGroup.allSelected"
                @change="allVectorsSelectedChanged(vectorGroup, $event)"
              >
                {{ vectorGroup.Key }}
              </b-form-checkbox>
            </div>
            <div class="panel-body">
              <b-row v-for="vector in vectorGroup.Value.vectors" :key="vector.id">
                <b-col>
                  <b-form-checkbox
                    v-model="vector.isChecked"
                    @change="updateVectors(vectorGroup, vector, $event)"
                  >
                    {{ vector.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-btn class="mt-3" variant="success" @click="onModalClose">Search</b-btn>
      </template>
    </b-modal>

    <b-modal ref="servicesModal" scrollable size="lg">
      <template #modal-header>
        <div class="d-flex align-items-center justify-content-between w-100">
          <div>
            <h5>Services</h5>
            <div>Select the ITU service to view all entries in the frequency range that this ITU service is in</div>
          </div>
          <div class="d-flex flex-column align-items-end">
            <b-btn variant="link" class="m-0 p-0" @click="$refs.servicesModal.hide()">
              <font-awesome-icon icon="times" />
            </b-btn>
            <b-form-checkbox
              v-model="allServicesSelected"
              @change="allServicesSelectedChanged"
            >
              Select all
            </b-form-checkbox>
          </div>
        </div>
      </template>
      <div id="services-modal-content" class="panel panel-default">
        <div class="panel-body">
          <!-- Refactor this :/ -->
          <ul class="list-group">
            <li
              v-for="servicesGroup in optionServices.childs"
              :key="servicesGroup.id"
              class="list-group-item"
            >
              <b-form-checkbox v-model="servicesGroup.selected">{{ servicesGroup.name }}</b-form-checkbox>
              <ul v-if="servicesGroup.childs.length" class="list-group">
                <li
                  v-for="childService in servicesGroup.childs"
                  :key="childService.id"
                  class="list-group-item"
                >
                  <b-form-checkbox v-model="childService.selected">{{ childService.name }}</b-form-checkbox>
                  <ul v-if="childService.childs.length" class="list-group">
                    <li
                      v-for="childChildService in childService.childs"
                      :key="childChildService.id"
                      class="list-group-item"
                    >
                      <b-form-checkbox v-model="childChildService.selected">
                        {{ childChildService.name }}
                        <ul v-if="childChildService.childs.length" class="list-group">
                          <li
                            v-for="childChildChildService in childChildService.childs"
                            :key="childChildChildService.id"
                            class="list-group-item"
                          >
                            <b-form-checkbox
                              v-model="childChildChildService.selected"
                            >
                              {{ childChildChildService.name }}
                            </b-form-checkbox>
                          </li>
                        </ul>
                      </b-form-checkbox>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <template #modal-footer>
        <b-btn class="mt-3" variant="success" @click="onModalClose">Search</b-btn>
      </template>
    </b-modal>

    <b-modal ref="applicationsModal" scrollable size="lg">
      <template #modal-header>
        <div class="d-flex align-items-center justify-content-between w-100">
          <h5>Applications</h5>
          <div class="d-flex flex-column align-items-end">
            <b-btn variant="link" class="m-0 p-0" @click="$refs.applicationsModal.hide()">
              <font-awesome-icon icon="times" />
            </b-btn>
            <b-form-checkbox
              v-model="allApplicationsSelected"
              @change="allApplicationsSelectedChanged"
            >
              Select all
            </b-form-checkbox>
          </div>
        </div>
      </template>
      <div id="applications-modal-content" class="panel panel-default">
        <div class="panel-body">
          <ul v-if="applications.length" class="list-group">
            <li v-for="application in applications" :key="application.code" class="list-group-item">
              <b-form-checkbox v-model="application.selected">{{ application.code }}</b-form-checkbox>
            </li>
          </ul>
        </div>
      </div>
      <template #modal-footer>
        <b-btn class="mt-3" variant="success" @click="onModalClose">Search</b-btn>
      </template>
    </b-modal>
  </b-collapse>
</template>
<script>
import { mapActions, mapGetters } from "vuex"

import { ValidationProvider, ValidationObserver, extend } from "vee-validate"
import { min_value, required } from "vee-validate/dist/rules"

import noUiSlider from "nouislider"
import "nouislider/distribute/nouislider.css"

extend("min_value", min_value)
extend("required", required)

import * as api from "@/services/api.js"
import * as files from "@/services/files.js"
import * as texts from "@/services/texts.js"

const modes = ["table", "allocations", "applications"]

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      optionPresets: [],
      optionServices: {
        childs: []
      },
      optionVectors: [],
      applications: [],
      filters: {
        idPreset: null,
        ituServices: [],
        applications: [],
        vectorDef: [],
        lowerFreq: 0,
        upperFreq: 1,
        selectedLowerFreq: 1000000,
        selectedUpperFreq: 1000000,
        searchString: "",
        selectedRestrictions: ""
      },
      filtersFetched: false,
      filtersChangedBecauseOfPreset: false,
      metrices: [{
        name: "Hz",
        metric: 1
      }, {
        name: "kHz",
        metric: 1000
      }, {
        name: "MHz",
        metric: 1000000
      }, {
        name: "GHz",
        metric: 1000000000
      }],
      allServicesSelected: false,
      allApplicationsSelected: false,
      selectedRestrictions: "",
      mode: modes[0],
      filtersInitialized: false,
      loading: false,
      loadingOffline: false,
      bands: [],
      freqSliderExpanded: false,
      selectedLowerBand: null,
      selectedUpperBand: null
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "persistedFilters"]),
    vectorsOutput() {
      const ret = []
      this.optionVectors.forEach((vectorGroup) => {
        vectorGroup.Value.vectors.forEach((vector) => {
          if (vector.isChecked)
            ret.push(vector.id)
        })
      })
      return ret
    },
    servicesOutput() {
      const ret = []

      //Refactor this :/
      this.optionServices.childs.forEach((service) => {
        if (service.selected) {
          ret.push(service.code)
        }

        service.childs.forEach((serviceChild) => {
          if (serviceChild.selected) {
            ret.push(serviceChild.code)
          }

          serviceChild.childs.forEach((serviceChildChild) => {
            if (serviceChildChild.selected) {
              ret.push(serviceChildChild.code)
            }

            serviceChildChild.childs.forEach((serviceChildChildChild) => {
              if (serviceChildChildChild.selected) {
                ret.push(serviceChildChildChild.code)
              }
            })
          })
        })
      })

      return ret
    },
    applicationsOutput() {
      return this.applications.filter(a => a.selected).map(a => a.code)
    },
    anyVectorSelected() {
      return this.vectorsOutput.length
    },
    anyServicesSelected() {
      return this.servicesOutput.length
    },
    anyApplicationSelected() {
      return this.applicationsOutput.length
    },
    filtersOutput() {
      return {
        lowerFreq: this.filters.lowerFreq * this.filters.selectedLowerFreq,
        upperFreq: this.filters.upperFreq * this.filters.selectedUpperFreq,
        vectorDef: this.filters.vectorDef,
        vectorInstance: null,
        searchString: this.filters.searchString,
        ituServices: this.filters.ituServices,
        applications: this.filters.applications,
        restrictions: this.filters.selectedRestrictions
      }
    }
  },
  watch: {
    vectorsOutput(val) {
      if (!this.filtersInitialized) {
        return
      }
      this.filters.vectorDef = val
    },
    servicesOutput(val) {
      if (!this.filtersInitialized) {
        return
      }
      this.filters.ituServices = val
    },
    applicationsOutput(val) {
      if (!this.filtersInitialized) {
        return
      }
      this.filters.applications = val
    },
    filters: {
      handler(val) {
        this.setPersistedFilters(val)
        this.updateSlider()
      },
      deep: true
    },
    filtersOutput() {
      if (this.filtersInitialized && !this.filtersChangedBecauseOfPreset) {
        this.filters.idPreset = null
      }

      if (this.filtersChangedBecauseOfPreset) {
        this.filtersChangedBecauseOfPreset = false
      }
    },
    $route() {
      this.setFreqsFromParams()
    }
  },
  async created() {
    document.addEventListener("keydown", this.onKeyDown)
    await this.fetchFiltersData()
    this.initFilters()

    if (this.$route.name === "fatTable")
      this.search()
  },
  mounted() {
    this.initSlider()
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown)
  },
  methods: {
    ...mapActions(["setPersistedFilters"]),
    async initFilters() {
      this.filters = { ...this.persistedFilters }
      this.initVectors()
      this.initServices()
      this.initApplications()
      this.initPreset()
      this.setFreqsFromParams()

      await this.$nextTick()
      this.filtersInitialized = true
    },
    initVectors() {
      this.optionVectors.forEach((option) => {
        option.Value.vectors.forEach((vector) => {
          this.$set(vector, "isChecked", this.filters.vectorDef.includes(vector.id))
        })
        this.$set(option, "allSelected", option.Value.vectors.length && option.Value.vectors.filter((v) => !v.isChecked).length === 0)
        this.$set(option, "anySelected", option.Value.vectors.length && option.Value.vectors.filter((v) => v.isChecked).length > 0)
      })
    },
    initServices() {
      this.optionServices.childs.forEach((service) => {
        this.$set(service, "selected", this.filters.ituServices.includes(service.code))
        service.childs.forEach((serviceChild) => {
          this.$set(serviceChild, "selected", this.filters.ituServices.includes(serviceChild.code))
          //TODO, refactor?
          serviceChild.childs.forEach((serviceChildChild) => {
            this.$set(serviceChildChild, "selected", this.filters.ituServices.includes(serviceChildChild.code))

            serviceChildChild.childs.forEach((serviceChildChildChild) => {
              this.$set(serviceChildChildChild, "selected", this.filters.ituServices.includes(serviceChildChildChild.code))
            })
          })
        })
      })
    },
    initApplications() {
      this.applications.forEach((application) =>
        this.$set(application, "selected", this.filters.applications.includes(application.code))
      )
    },
    initPreset() {
      if (this.filters.idPreset == null) {
        const defaultPreset = this.optionPresets.find((p) => p.isDefault)
        if (defaultPreset) {
          this.filters.idPreset = defaultPreset.id
          this.changePresets(true)
        }
      }
    },
    setFreqsFromParams() {
      if (this.$route.params.pathMatch) {
        this.mode = this.$route.params.pathMatch
      }

      if (this.$route.params.freqFrom == null || this.$route.params.freqTo == null) {
        return
      }

      const freqFromParsed = this.$route.params.freqFrom.match(/(\d+\.?\d*)(\S+)/)
      const freqToParsed = this.$route.params.freqTo.match(/(\d+\.?\d*)(\S+)/)

      const freqFrom = freqFromParsed[1]
      const freqTo = freqToParsed[1]
      const freqFromUnit = freqFromParsed[2]
      const freqToUnit = freqToParsed[2]

      this.filters.lowerFreq = 1 * freqFrom
      this.filters.upperFreq = 1 * freqTo

      this.filters.selectedLowerFreq = this.getMetricFromUnit(freqFromUnit)
      this.filters.selectedUpperFreq = this.getMetricFromUnit(freqToUnit)
    },
    getMetricFromUnit(unit) {
      const result = this.metrices.find((m) => m.name === unit)
      return result?.metric || 1000000
    },
    async fetchFiltersData() {
      return await Promise.all([
        this.fetchPresetsData(),
        this.fetchServicesData(),
        this.fetchVectorsData(),
        this.fetchRadioSystemsData()])
    },
    async fetchPresetsData() {
      const response = await api.getPresets()
      this.optionPresets = response.data || []
    },
    async fetchServicesData() {
      const response = await api.getServices()
      this.optionServices = response.data
    },
    async fetchVectorsData() {
      const response = await api.getVectors()
      this.optionVectors = response.data
    },
    async fetchRadioSystemsData() {
      const response = await api.getApplications()
      this.applications = response.data
    },
    changePresets(initial) {
      if (this.filters.idPreset == null) {
        this.optionVectors.forEach((option) => {
          option.Value.vectors.forEach((vector) => {
            this.$set(vector, "isChecked", true)
          })
          this.$set(option, "allSelected", true)
        })
      }

      const preset = this.optionPresets.find((p) => p.id === this.filters.idPreset)

      if (preset) {
        this.filters.selectedUpperFreq = "1000000"
        this.filters.selectedLowerFreq = "1000000"

        this.filters.lowerFreq = preset.lowerFreq / this.filters.selectedLowerFreq
        this.filters.upperFreq = preset.upperFreq / this.filters.selectedUpperFreq

        this.filters.searchString = preset.searchString

        if (preset.vectors?.length) {
          this.filters.vectorDef = preset.vectors
          this.initVectors()
          this.$emit("vectors-changed")
        }

        this.filters.ituServices = preset.codService ? [preset.codService] : []
        this.initServices()
        if (!initial) {
          this.filtersChangedBecauseOfPreset = true
        }
      }
    },
    updateVectors(vectorGroup, vector, val) {
      vector.isChecked = val
      vectorGroup.allSelected = Boolean(!vectorGroup.Value.vectors.find((v) => !v.isChecked))
      vectorGroup.anySelected = Boolean(vectorGroup.Value.vectors.find((v) => v.isChecked))
      this.$emit("vectors-changed")
    },
    lowerFreqUnitChange(value) {
      const oldValue = this.filters.selectedLowerFreq
      this.filters.selectedLowerFreq = value
      this.filters.lowerFreq = (this.filters.lowerFreq * oldValue) / this.filters.selectedLowerFreq
    },
    upperFreqUnitChange(value) {
      const oldValue = this.filters.selectedUpperFreq
      this.filters.selectedUpperFreq = value
      this.filters.upperFreq = (this.filters.upperFreq * oldValue) / this.filters.selectedUpperFreq
    },
    async search() {
      this.loading = true
      this.$emit("before-search")
      try {
        await this.$nextTick()
        if (this.mode === "table") {
          await this.getFatTable()
        } else if (this.mode === "allocations") {
          await this.getFatChart()
        } else {
          await this.getFatChartApplications()
        }
      } finally {
        this.loading = false
      }
    },
    allServicesSelectedChanged(val) {
      this.optionServices.childs.forEach((service) => {
        service.selected = val
        service.childs.forEach((serviceChild) => {
          serviceChild.selected = val
          serviceChild.childs.forEach((serviceChildChild) => {
            serviceChildChild.selected = val
            serviceChildChild.childs.forEach((serviceChildChildChild) => {
              serviceChildChildChild.selected = val
            })
          })
        })
      })
    },
    allApplicationsSelectedChanged(val) {
      this.applications.forEach((application) => {
        application.selected = val
      })
    },
    allVectorsSelectedChanged(vectorGroup, val) {
      vectorGroup.Value.vectors.forEach((vector) => {
        this.updateVectors(vectorGroup, vector, val)
      })
    },
    async getFatTable() {
      const response = await api.getFatTableOnlineData(this.filtersOutput)
      this.$emit("search", { data: response.data, vectors: this.optionVectors, filters: this.filters, mode: this.mode })
    },
    async getFatChart() {
      const result = await api.getFatChartData(this.filtersOutput)
      this.$emit("search", { data: result.data, vectors: this.optionVectors, filters: this.filters, mode: this.mode })
    },
    async getFatChartApplications() {
      const result = await api.getApplicationChart(this.filtersOutput)
      this.$emit("search", { data: result.data, vectors: this.optionVectors, filters: this.filters, mode: this.mode, applications: this.applications })
    },
    async getFatTableOffline() {
      this.loadingOffline = true
      try {
        const result = await api.getFatTableOfflineData(this.filtersOutput)
        files.downloadBlob(result, "fat-table-offline.html", "text/html")
      } finally {
        this.loadingOffline = false
      }
    },
    async onModeChange() {
      await this.$nextTick()
      this.$router.push({
        name: "fatTable",
        params: {
          pathMatch: this.mode
        }
      })
      this.search()
    },
    onModalClose() {
      this.$refs.servicesModal.hide()
      this.$refs.vectorsModal.hide()
      this.$refs.applicationsModal.hide()
      this.search()
    },
    onKeyDown(event) {
      if (event.key === "Enter") {
        this.search()
      }
    },
    async initSlider() {
      const { data: bands } = await api.getBands()
      this.bands = bands
      const upperFreqs = this.bands.map(b => b.UpperFreq)
      const max = Math.max(...upperFreqs)

      const range = {
        min: 0,
        max
      }

      upperFreqs.forEach((freq, index) => {
        range[`${(index + 1) / upperFreqs.length * 100}%`] = freq
      })

      noUiSlider.create(this.$refs.slider, {
        start: [
          this.filters.lowerFreq * this.filters.selectedLowerFreq,
          this.filters.upperFreq * this.filters.selectedUpperFreq],
        range,
        pips: {
          mode: "steps",
          values: upperFreqs,
          format: {
            to(value) {
              return texts.formatFrequency(value)
            },
            from(value) {
              return Number(value)
            }
          }
        }
      })

      this.$refs.slider.noUiSlider.on("change", (values) => {
        this.filters.lowerFreq = (values[0] / this.filters.selectedLowerFreq).toFixed(2)
        this.filters.upperFreq = (values[1] / this.filters.selectedUpperFreq).toFixed(2)
        this.resetSelectedBands()
      })
    },
    updateSlider() {
      this.$refs.slider.noUiSlider?.set([
        this.filters.lowerFreq * this.filters.selectedLowerFreq,
        this.filters.upperFreq * this.filters.selectedUpperFreq
      ])
    },
    async toggleFreqSlider() {
      this.freqSliderExpanded = !this.freqSliderExpanded
      await this.$nextTick()
      this.$emit("height-change")
    },
    resetSelectedBands() {
      this.selectedLowerBand = this.selectedUpperBand = null
    },
    selectBand(band) {
      if (!this.selectedLowerBand || this.selectedUpperBand) {
        this.selectedLowerBand = band
        this.selectedUpperBand = null
      } else {
        this.selectedUpperBand = band
      }

      if (this.selectedLowerBand && this.selectedUpperBand) {

        const index1 = this.bands.indexOf(this.selectedLowerBand)
        const index2 = this.bands.indexOf(this.selectedUpperBand)

        if (index1 > index2) {
          this.selectedLowerBand = this.bands[index2]
          this.selectedUpperBand = this.bands[index1]
        }

        this.filters.lowerFreq = this.selectedLowerBand.LowerFreq / this.filters.selectedLowerFreq
        this.filters.upperFreq = this.selectedUpperBand.UpperFreq / this.filters.selectedUpperFreq
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#filtersContainer {
  background-color: #f3f3f3;
  overflow-x: auto;

  .modal-buttons-col {
    padding-left: 10px;
  }

  > span > .row {
    min-width: 900px;
  }

  .input-group-prepend {
    width: 110px;

    b {
      width: 100%;
    }
  }

  .input-group-append {
    select {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .col-9 > .row,
  .col > .row {
    padding: 2px;
  }

  .validation-container {
    flex: 1 1 0%;

    input {
      border-radius: 0;
    }
  }

  div[role="radiogroup"] {
    line-height: 1;
  }

  .custom-radio {
    min-height: 1rem;
  }

  .slider-container {
    height: 100px;
    width: 100%;
    margin-left: 30px;
    margin-right: 20px;
    margin-top: 5px;
    .slider {
      width: 100%;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 992px) {
    .input-group-prepend {
      width: 90px;
    }

    .btn,
    .form-control,
    .input-group-text,
    .custom-select,
    .custom-radio {
      font-size: 0.8rem;
    }

    ::v-deep .noUi-value {
      font-size: 0.75rem;
      margin-top: 5px;
    }
  }
}

#services-modal-content {
  ul li.list-group-item {
    padding: 2px;
    padding-left: 8px;

    ul {
      padding: 8px;
    }
  }
}
</style>