<template>
  <tbody>
    <tr class="info-row">
      <td class="font-weight-bold">#{{ index + 1 }}</td>
      <td class="font-weight-bold">
        <b-btn variant="link" :href="`#/rir/RIR${item.rirId}/${item.bodyUid}`" class="p-0"
               target="_blank"
        >
          RIR{{ item.rirFullId }}
        </b-btn>
      </td>
    </tr>
    <tr>
      <td>Name: {{ item.name }}</td>
      <td>Frequency: {{ frequencyFormatted }}</td>
    </tr>
    <tr>
      <td>RIRID: {{ item.rirId }}</td>
      <td>Channelling: {{ item.channelling }}</td>
    </tr>
    <tr>
      <td>Allocation: {{ item.allocationCode }}</td>
      <!-- eslint-disable vue/no-v-html -->
      <td>
        Power:
        <span v-html="power" />
      </td>
    </tr>
    <tr>
      <td>Application: {{ application }}</td>
      <td />
    </tr>
  </tbody>
</template>

<script>
import * as texts from "@/services/texts.js"

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    application: {
      type: String,
      required: true
    }
  },
  computed: {
    power() {
      return this.item.power.replace(/(?:\r\n|\r|\n)/g, "<br>")
    },
    frequencyFormatted() {
      return `${texts.formatFrequency(this.item.lowerFrequency)} - ${texts.formatFrequency(this.item.upperFrequency)}`
    }
  }
}
</script>
