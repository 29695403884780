<template>
  <div>
    <fat-header
      @before-search="loading = true"
      @search="onSearch"
      @height-change="redraw"
      @reset-columns="resetColumnWidth"
      @reset-zoom="resetZoom"
      @vectors-changed="onVectorsChanged"
    />

    <div v-if="loading" class="loader-fixed d-flex justify-content-center mb-3">
      <b-spinner label="Loading..." :variant="isTableMode ? 'primary' : 'success'" />
    </div>
    <template v-if="fatData">
      <fat-table v-if="isTableMode" ref="table" :fat-data="fatData"
                 :filters="filters" :vectors="vectors"
      />
      <fat-chart-allocations v-else-if="isAllocationsMode" :fat-data="fatData" :filters="filters" />
      <fat-chart-applications v-else ref="applications" :fat-data="fatData"
                              :filters="filters" :applications="applications"
      />
    </template>
  </div>
</template>

<script>
import FatHeader from "@/components/FatHeader.vue"
import FatChartAllocations from "@/components/FatChartAllocations.vue"
import FatChartApplications from "@/components/FatChartApplications.vue"
import FatTable from "@/components/FatTable.vue"

export default {
  name: "Fat",
  components: {
    FatHeader,
    FatChartAllocations,
    FatChartApplications,
    FatTable
  },
  data() {
    return {
      fatData: null,
      filters: {},
      loading: false,
      vectors: [],
      applications: [],
      mode: "table"
    }
  },
  computed: {
    isTableMode() {
      return this.mode === "table"
    },
    isAllocationsMode() {
      return this.mode === "allocations"
    }
  },
  methods: {
    onSearch({ data, vectors, filters, mode, applications }) {
      this.filters = filters
      this.mode = mode
      this.vectors = vectors
      this.applications = applications
      this.loading = false
      this.fatData = data
    },
    onVectorsChanged() {
      this.$refs.table?.onVectorsChanged()
    },
    redraw() {
      this.$refs.table?.setGridHeight()
      this.$refs.applications?.resetZoom()
    },
    resetColumnWidth() {
      this.$refs.table?.resetColumnWidth()
    },
    resetZoom() {
      this.$refs.applications?.resetZoom()
    }
  }
}
</script>
