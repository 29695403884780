import axios from "axios"
import store from "@/store"
import eventBus from "@/events.js"
import * as rirsOffline from "@/services/rirsOffline.js"

const getToken = () => store.getters["token"]

export const getFatTableOnlineData = (filters) => axios.post("/api/GetFFAT", { ...filters, token: getToken() })

export const getFatTableOfflineData = (filters) => axios.post("/api/GetFATREP", { ...filters, token: getToken() })

export const getFatChartData = (filters) => axios.post("/api/GetFATOWV", { ...filters, token: getToken() })

export const getPresets = () => axios.get(`/api/GetPresets/${getToken()}`)

export const getServices = () => axios.get(`/api/GetServicesTree/${getToken()}`)

export const getVectors = () => axios.get(`/api/GetVectors/${getToken()}`)

export const getBands = () => axios.get(`/api/GetBands/${getToken()}`)

export const getApplications = () => axios.get(`/api/GetApplications/${getToken()}`)

export const getApplicationChart = (filters) => axios.post("/api/GetApplicationChart", { ...filters, token: getToken() })

export const getStats = () => axios.get("/api/getStats")

export const getMoreStats = () => axios.get(`/api/getMoreStats/${getToken()}`)

export const getDocs = () => axios.get(`api/docs/${getToken()}`)

export const getDocSrc = (uid) => `./api/docs/${getToken()}/${uid}`

export const getRir = (id) => axios.get(`/api/rir/body/${getToken()}/${id}`)

export const checkHelpFilePresence = () => axios.head("/assets/rirs-help.pdf")

export const getRirs = (params) => {
  if (!Number(process.env.VUE_APP_OFFLINE)) {
    return axios.post("api/RIR/findRIR", { ...params, token: getToken() })
  }

  return rirsOffline.getRirs(params)
}

export const getRirBody = (id, modeVal, outputFormat, arrayBuffer) => {
  if (!Number(process.env.VUE_APP_OFFLINE)) {
    return axios.get(`/api/rir/body/${getToken()}/${id}/${modeVal}/${outputFormat}`, arrayBuffer ? { responseType: "arraybuffer" } : {})
  }

  return rirsOffline.getRirBody(id, modeVal)
}

export const login = (user, password) => axios.post("/api/doLogin", { User: user, Password: password})

export const logout = () => axios.get(`/api/doLogout/${getToken()}`)

export const setup = () => {
  axios.interceptors.response.use((response) => {
    if (response.data.resultCode === "MAINT_MODE") {
      eventBus.$emit("maintenance-mode")
      return Promise.reject(response)
    }

    if (response.data.resultCode === "TOKEN_EXPIRED") {
      eventBus.$emit("token-expired")
      return Promise.reject(response)
    }

    if (response.data.resultCode === "DENY") {
      eventBus.$emit("access-denied")
      return Promise.reject(response)
    }

    if (response.data) {
      return response.data
    }

    return response
  }, (error) => {
    return Promise.reject(error)
  })
}