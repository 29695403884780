<template>
  <div class="p-4">
    <table class="table table-bordered table-striped table-sm">
      <thead>
        <tr>
          <td width="30%" style="max-width:300px">Name</td>
          <td width="65%">Description</td>
          <td width="5%" style="min-width:100px">Edition</td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(doc, index) in docs"
          :key="index"
          :style="{'background-color': doc.state === 'Decorated' ? 'aquamarine' : 'white'}"
        >
          <td>
            <a target="_blank" :href="doc.src">{{ doc.name }}</a>
          </td>
          <td>{{ doc.description }}</td>
          <td>{{ doc.version }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import * as api from "@/services/api.js"

export default {
  data() {
    return {
      docs: []
    }
  },
  async created() {
    const result = await api.getDocs()
    this.docs = result.data
    this.docs.forEach((doc) => {
      doc.src = api.getDocSrc(doc.uid)
    })
  }
}
</script>
