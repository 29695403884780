<template>
  <table class="table table-bordered table-sm rirs-table">
    <thead>
      <tr>
        <th class="cursor-pointer">
          <div class="d-flex">
            <span class="caretContainer pr-1" @click="$emit('toggle-all-to-bodies')">
              <font-awesome-icon v-if="!allHeadersExpanded" icon="chevron-down" />
              <font-awesome-icon v-else icon="chevron-up" />
            </span>

            <span class="caretContainer pr-1" @click="$emit('toggle-all-to-positions')">
              <div v-if="!allBodiesExpanded" class="double-chevron-container">
                <font-awesome-icon icon="chevron-down" />
                <font-awesome-icon icon="chevron-down" />
              </div>
              <div v-else class="double-chevron-container">
                <font-awesome-icon icon="chevron-up" />
                <font-awesome-icon icon="chevron-up" />
              </div>
            </span>

            <span @click="$emit('sort', 'name')">RIR Name</span>
            <span v-if="sortBy === 'name'" class="caretContainer pl-1" @click="$emit('sort', 'name')">
              <font-awesome-icon v-if="sortAsc" icon="chevron-down" />
              <font-awesome-icon v-else icon="chevron-up" />
            </span>
          </div>
        </th>
        <th class="cursor-pointer" @click="$emit('sort', 'freq')">
          <span>Frequency Band</span>
          <span v-if="sortBy === 'freq'" class="caretContainer pl-1">
            <font-awesome-icon v-if="sortAsc" icon="chevron-down" />
            <font-awesome-icon v-else icon="chevron-up" />
          </span>
        </th>
        <th>Remarks</th>
        <th>Version</th>
        <th>Date</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="header in rirsHeaders">
        <rirs-table-header-row
          :key="header.id"
          :header="header"
          :mode="mode"
          :selection-mode="selectionMode"
          :sort-by="sortBy"
          @toggle-header="$emit('toggle-header', header)"
          @toggle-header-deep="$emit('toggle-header-deep', header)"
          @header-selected="$emit('header-selected', header)"
        />
        <template v-for="(body, index) in header.bodies">
          <rirs-table-body-row
            :key="`${header.id}-${index}-body`"
            :body="body"
            :header="header"
            :mode="mode"
            :selection-mode="selectionMode"
            @toggle-body="$emit('toggle-body', { header, index })"
            @body-selected="$emit('body-selected', { header, index })"
          />
          <rirs-table-positions-table
            :key="`${header.id}-${index}-positions`"
            :body="body"
            :mode="mode"
            :positions="body.positions"
            :selection-mode="selectionMode"
            @position-selected="$emit('position-selected', { header, index, positionIndex: $event })"
            @position-selected-all="$emit('position-selected-all', { header, index })"
          />
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
import RirsTableHeaderRow from "@/components/RirsTableHeaderRow.vue"
import RirsTableBodyRow from "@/components/RirsTableBodyRow.vue"
import RirsTablePositionsTable from "@/components/RirsTablePositionsTable.vue"

import * as rirs from "@/services/rirs.js"

export default {
  components: {
    RirsTableHeaderRow,
    RirsTableBodyRow,
    RirsTablePositionsTable
  },
  props: {
    rirsHeaders: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: null
    },
    sortAsc: {
      type: Boolean,
      default: false
    },
    mode: {
      type: Boolean,
      default: false
    },
    selectionMode: {
      type: String,
      required: true
    }
  },
  computed: {
    visibleHeaders() {
      return this.rirsHeaders.filter(h => this.isHeaderVisible(h))
    },
    allHeadersExpanded() {
      return this.visibleHeaders.every(h => h.expanded)
    },
    allBodiesExpanded() {
      return this.visibleHeaders.every(h => h.bodies.filter(b => this.isBodyVisible(b)).every(b => b.expanded))
    }
  },
  methods: {
    isBodyVisible(body) {
      return rirs.isBodyVisible(body, this.selectionMode, this.mode)
    },
    isHeaderVisible(header) {
      return rirs.isHeaderVisible(header, this.selectionMode, this.mode)
    }
  }
}
</script>

<style lang="scss" scoped>
.rirs-table {
  ::v-deep .double-chevron-container {
    position: relative;
    height: 20px;
    display: flex;

    svg:last-child {
      position: absolute;
      left: 0;
      top: 6px;
    }
  }

  ::v-deep .caretContainer {
    cursor: pointer;
  }

  td {
    white-space: normal;
  }

  .background-gray {
    background-color: #e0e0e0 !important;
  }

  ::v-deep .filtered {
    font-weight: bold;

    a {
      font-weight: bold;
    }
  }
}
</style>