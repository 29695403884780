<template>
  <tr v-show="showHeader">
    <td colspan="5" class="positions-table-container">
      <table class="table table-bordered table-sm details-table mt-2 ml-3">
        <thead>
          <tr class="background-gray">
            <th>
              <b-form-checkbox
                :indeterminate="body.anyPositionSelected && !allSelectedIndicator"
                :checked="allSelectedIndicator"
                @change="$emit('position-selected-all')"
              >
                Nr
              </b-form-checkbox>
            </th>
            <th>Parameter</th>
            <th>Description</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody v-show="showTable">
          <rirs-table-positions-table-row
            v-for="(position, index) in positions"
            :key="index"
            :index="index"
            :selection-mode="selectionMode"
            :position="position"
            @position-selected="$emit('position-selected', index)"
          />
        </tbody>
      </table>
    </td>
  </tr>
</template>

<script>
import RirsTablePositionsTableRow from "@/components/RirsTablePositionsTableRow.vue"

export default {
  components: {
    RirsTablePositionsTableRow
  },
  props: {
    positions: {
      type: Array,
      default: () => []
    },
    body: {
      type: Object,
      required: true
    },
    selectionMode: {
      type: String,
      required: true
    },
    mode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allSelectedIndicator: false
    }
  },
  computed: {
    fixedForSelection() {
      return this.selectionMode == "selected" && (this.body.selected || this.body.anyPositionSelected)
    },
    showHeader() {
      return this.body.expanded && (this.body.filtered || this.mode) && (this.showTable || this.body.selected || this.fixedForSelection) 
    },
    showTable() {
      return this.selectionMode === "all" || this.fixedForSelection
    },
    allSelected() {
      return this.positions.every(p => p.selected)
    }
  },
  watch: {
    allSelected() {
      this.updateAllSelected()
    }
  },
  created() {
    this.updateAllSelected()
  },
  methods: {
    async updateAllSelected() {
      //Wasn't working when using allSelected directly
      await this.$nextTick()
      this.allSelectedIndicator = this.allSelected
    }
  }
}
</script>

<style lang="scss" scoped>
.positions-table-container {
  padding-left: 35px;
  .details-table {
    background-color: #f9f9f9 !important;
    border: 2px solid black;
    width: calc(100% - 24px);
  }
}
</style>