<template>
  <b-navbar class="py-0" fixed="top">
    <div class="navbar-header">
      <a
        v-if="useCustomLogos"
        class="navbar-brand"
        :href="topBar.logoUrl"
        target="_blank"
      >
        <img src="/HeaderLogo.png" alt="Application logo" style="width:auto; height:28px;">
      </a>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav mr-auto">
        <template v-if="isOnline">
          <li
            class="nav-item py-2 px-0 mr-1"
            :class="{'router-link-active': isFatTableRoute}"
            @click="onTableClick"
          >
            <a class="nav-link px-0">Table</a>
          </li>
          <li class="py-2 d-flex" :class="{'router-link-active': isFatTableRoute}">
            <b-button
              v-if="isFatTableRoute"
              v-b-toggle.filtersContainer
              variant="link"
              class="p-0 collapsable"
            >
              <font-awesome-icon class="when-open" icon="chevron-up" />
              <font-awesome-icon class="when-closed" icon="chevron-down" />
            </b-button>
            <span v-else class="px-2" />
          </li>
        </template>
        <li
          class="nav-item py-2 px-0 mr-1 ml-2"
          :class="{'router-link-active': isRirsTableRoute}"
          @click="onRirsClick"
        >
          <a class="nav-link px-0">RIRs</a>
        </li>
        <li class="py-2 d-flex" :class="{'router-link-active': isRirsTableRoute}">
          <b-button
            v-if="isRirsTableRoute"
            v-b-toggle.rirsFiltersContainer
            variant="link"
            class="p-0 collapsable"
          >
            <font-awesome-icon class="when-open" icon="chevron-up" />
            <font-awesome-icon class="when-closed" icon="chevron-down" />
          </b-button>
          <span v-else class="px-2" />
        </li>
        <router-link v-if="isOnline" tag="li" to="/docs"
                     class="nav-item py-2 mx-1"
        >
          <a class="nav-link">DOCs</a>
        </router-link>
      </ul>
      <div
        ref="mainTitle"
        class="nav navbar-nav nav-fat-title"
        style="position: absolute; left: 50%; transform: translatex(-50%);"
      >
        <b>Frequency Allocation Plan</b>
      </div>
      <div v-if="topBar.message.text" 
           ref="messageContainer"
           class="top-bar-message mr-2" 
           :title="topBar.message.text"
           :style="{ 
             'color': topBar.message.color,
             'backgroundColor': topBar.message.backgroundColor,
             'font-size': `${topBar.message.fontSize}px`,
             'font-weight': topBar.message.fontWeight,
             'max-width': `${topBarMessageMaxWidth}px`
           }"
      >
        {{ topMessageTruncated }} 
      </div>
      <div class="mr-2">
        <span v-if="info.version">v.{{ info.version }}</span>
        <span v-else>Offline</span>
        <span>/</span>
        <span>{{ appVersion }}</span>
      </div>
      <template v-if="isOnline && intranet">
        <div v-if="!isLoggedIn">
          <a class="btn btn-primary ml-2" href="#/login">Log in</a>
        </div>
        <div v-if="isLoggedIn" class="mx-2">
          <span class="badge badge-success">{{ userName }}</span>
        </div>
        <div v-if="isLoggedIn">
          <button type="submit" class="btn btn-primary ml-2" @click="logout">Log out</button>
        </div>
      </template>
    </div>
  </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import * as api from "@/services/api.js"

export default {
  props: {
    filtersOptionsActive: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOffline: Boolean(Number(process.env.VUE_APP_OFFLINE)),
      appVersion: process.env2.PACKAGE_VERSION || "0",
      topBarMessageMaxWidth: null
    }
  },
  computed: {
    ...mapGetters(["intranet", "info", "isLoggedIn", "userName", "topBar", "useCustomLogos"]),
    isOnline() {
      return !this.isOffline
    },
    isFatTableRoute() {
      return this.$route.name === "fatTable" || this.$route.name === "default"
    },
    isRirsTableRoute() {
      return this.$route.name === "rirs"
    },
    topMessageTruncated() {
      const messageLimit = 75
      return this.topBar.message.text.length > messageLimit
       ? `${this.topBar.message.text.substring(0, messageLimit)}...` 
       : this.topBar.message.text
    }
  },
  mounted() {
    this.repositionTopBarMessage()
    window.addEventListener("resize", this.repositionTopBarMessage)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.repositionTopBarMessage)
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    repositionTopBarMessage() {
      if (!this.$refs.mainTitle || !this.$refs.messageContainer) {
        return
      }
      const margins = 10
      const mainTitleClientRect = this.$refs.mainTitle.getBoundingClientRect()
      const mainTitleRightBoundaryPosition = mainTitleClientRect.x + mainTitleClientRect.width

      const messageClientRect = this.$refs.messageContainer.getBoundingClientRect()
      const messageClientRightBoundaryPosition = messageClientRect.x + messageClientRect.width

      this.topBarMessageMaxWidth = messageClientRightBoundaryPosition - mainTitleRightBoundaryPosition - margins
    },
    async logout() {
      api.logout()
      this.setUserInfo(null)
      this.$router.push("/login")
    },
    onTableClick() {
      if (this.isFatTableRoute) {
        this.$root.$emit("bv::toggle::collapse", "filtersContainer")
      } else {
        this.$router.push({ name: "fatTable" })
      }
    },
    onRirsClick() {
      if (this.isRirsTableRoute) {
        this.$root.$emit("bv::toggle::collapse", "rirsFiltersContainer")
      } else {
        this.$router.push({ name: "rirs" })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  background-color: white;
  box-shadow: 1px 1px 3px 1px #ccc;

  .nav-item {
    cursor: pointer;
  }

  .router-link-active {
    font-weight: bold;
    a {
      color: #007bff !important;
    }
  }

  .collapsable {
    &.collapsed > .when-open,
    &:not(.collapsed) > .when-closed {
      display: none;
    }
  }

  @media screen and (max-width: 992px) {
    .nav-fat-title {
      display: none;
    }
  }
}
</style>
