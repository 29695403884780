<template>
  <div>
    <div id="rir-container" class="container-fluid">
      <b-row>
        <b-col cols="3" class="menu-col pt-2">
          <h5 class="text-center font-weight-bold">Menu</h5>
          <table class="table table-sm">
            <tbody>
              <tr>
                <td>Highlight changes</td>
                <td>
                  <toggle-button
                    v-model="mode"
                    :height="30"
                    :width="110"
                    :disabled="loading"
                    :labels="{checked: 'On', unchecked: 'Off'}"
                    :color="{checked: '#007BFF', unchecked: '#28A745'}"
                    @change="updateHtml"
                  />
                </td>
              </tr>
              <tr v-if="isOnline">
                <td>Save as</td>
                <td>
                  <div class="d-flex">
                    <b-form-select v-model="outputFormat" :disabled="loading" size="sm">
                      <option value="pdf">PDF</option>
                      <option value="html">HTML</option>
                      <option value="docx">WORD</option>
                    </b-form-select>
                    <b-btn :disabled="loading" variant="primary" size="sm"
                           @click="download()"
                    >
                      <font-awesome-icon icon="download" />
                    </b-btn>
                  </div>
                </td>
              </tr>
              <tr v-if="isOffline">
                <td colspan="2">
                  <b-btn size="sm" variant="primary" @click="print">Print</b-btn>
                </td>
              </tr>
              <tr v-if="fatViewHref">
                <td>Show in table</td>
                <td>
                  <b-btn variant="primary" size="sm" :href="fatViewHref"
                         target="_blank"
                  >
                    View FAT
                  </b-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col cols="9" class="pt-2">
          <!-- eslint-disable vue/no-v-html -->
          <div v-if="html" id="htmlContainer" v-html="html" />
          <div v-if="loading" class="d-flex loader-fixed justify-content-center mb-3">
            <b-spinner label="Loading..." variant="primary" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/api.js"
import * as files from "@/services/files.js"

export default {
  data() {
    return {
      isOffline: Boolean(Number(process.env.VUE_APP_OFFLINE)),
      loading: false,
      mode: true,
      html: null,
      outputFormat: "docx",
      rirData: null
    }
  },
  computed: {
    isOnline() {
      return !this.isOffline
    },
    typeForFormat() {
      switch (this.outputFormat) {
        case "pdf":
          return "application/pdf"
        case "html":
          return "text/html"
        case "docx":
          return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      }
      return null
    },
    modeVal() {
      return this.mode ? 1 : 0
    },
    fatViewHref() {
      if (this.minFreq == undefined || this.maxFreq == undefined) {
        return null
      }

      const minFreqKHz = this.minFreq / 1000
      const maxFreqKHz = this.maxFreq / 1000
      return `#/fatTable/${minFreqKHz}kHz/${maxFreqKHz}kHz`
    },
    minFreq() {
      if (!this.rirData) {
        return null
      }

      return Math.min(...this.rirData.frequencyRanges.map(r => r.m_Item1))
    },
    maxFreq() {
      if (!this.rirData) {
        return null
      }

      return Math.max(...this.rirData.frequencyRanges.map(r => r.m_Item2))
    }
  },
 created() {
    this.updateHtml()
    if (this.isOnline) {
      this.fetchRirInfo()
    }
  },
  methods: {
    async updateHtml() {
      try {
        this.loading = true
        this.html = await this.fetchRirBody("html")
      } finally {
        this.loading = false
      }
    },
    async download() {
      const response = await this.fetchRirBody(this.outputFormat)
      files.downloadBlob(response, `Export.${this.outputFormat}`, this.typeForFormat)
    },
    async fetchRirBody(outputFormat) {
      return await api.getRirBody(this.$route.params.bodyId, this.modeVal, outputFormat, outputFormat !== "html")
    },
    async fetchRirInfo() {
      const response = await api.getRir(this.$route.params.bodyId)
      this.rirData = response.data
      document.title = `${this.$route.params.id}-${this.rirData.nb}`
    },
    print() {
      window.print()
    }
  }
}
</script>

<style lang="scss" scoped>
#rir-container {
  background-color: #dadada;
  min-height: calc(100vh - 55px);

  .menu-col {
    border-right: 1px solid gray;
    select {
      width: 110px;
    }
  }

  #htmlContainer {
    width: 785px;
    padding: 5px;
    border: 3px solid black;
    background-color: white;
    max-height: calc(100vh - 70px);
    overflow-y: auto;
  }
}

@media print {
  #rir-container {
    .menu-col {
      display: none;
    }

    .col-9 {
      width: 100%;
      max-width: 100%;
      flex: 1;
    }

    #htmlContainer {
      width: 100%;
    }
  }
}
</style>